import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Navbar from './components/Navbar';
import AppRoutes from './components/AppRoutes';
import { ToastContainer } from './utils/toast';
import ErrorBoundary from './components/ErrorBoundary';

const theme = extendTheme({
  colors: {
    brand: {
      50: '#e0f7f6',
      100: '#b3e6e4',
      500: '#21a09a',
      600: '#1c8c87',
    },
  },
});

function App() {
  return (
    <ErrorBoundary>
      <ChakraProvider theme={theme}>
        <AuthProvider>
          <Router>
            <Navbar />
            <AppRoutes />
            <ToastContainer />
          </Router>
        </AuthProvider>
      </ChakraProvider>
    </ErrorBoundary>
  );
}

export default App;