import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  HStack,
  Text,
  useDisclosure,
  IconButton,
} from '@chakra-ui/react';
import { AddIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import api from '../../utils/api';
import { toast } from '../../utils/toast';

const ManageTasks = ({ isOpen, onClose, courseId, lessonId, onTasksChange }) => {
  const [tasks, setTasks] = useState([]);
  const [currentTask, setCurrentTask] = useState(null);
  const [taskData, setTaskData] = useState({ title: '', description: '', link_notion: '', link_git: '' });
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();

  const fetchTasks = async () => {
    try {
      const response = await api.get(`/courses/${courseId}/lessons/${lessonId}/tasks`, { withCredentials: true });
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      toast({
        title: "Ошибка",
        description: "Не удалось загрузить задания",
        status: "error",
      });
    }
  };

  useEffect(() => {
    if (isOpen && lessonId) {
      fetchTasks();
    }
  }, [isOpen, lessonId]);

  const handleOpenEditModal = (task = null) => {
    if (task) {
      setCurrentTask(task);
      setTaskData(task);
    } else {
      setCurrentTask(null);
      setTaskData({ title: '', description: '', link_notion: '', link_git: '' });
    }
    onEditOpen();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (currentTask) {
        await api.put(`/courses/${courseId}/lessons/${lessonId}/tasks/update/${currentTask.id}`, taskData, { withCredentials: true });
        toast({
          title: "Задание обновлено",
          status: "success",
        });
      } else {
        await api.post(`/courses/${courseId}/lessons/${lessonId}/tasks/add`, taskData, { withCredentials: true });
        toast({
          title: "Задание добавлено",
          status: "success",
        });
      }
      fetchTasks();
      onEditClose();
    } catch (error) {
      console.error('Error saving task:', error);
      toast({
        title: "Ошибка",
        description: "Не удалось сохранить задание",
        status: "error",
      });
    }
  };

  const handleDelete = async (taskId) => {
    try {
      await api.delete(`/courses/${courseId}/lessons/${lessonId}/tasks/delete/${taskId}`, { withCredentials: true });
      toast({
        title: "Задание удалено",
        status: "success",
      });
      fetchTasks();
    } catch (error) {
      console.error('Error deleting task:', error);
      toast({
        title: "Ошибка",
        description: "Не удалось удалить задание",
        status: "error",
      });
    }
  };

  const handleCloseAndUpdate = () => {
    onTasksChange(tasks);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleCloseAndUpdate} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Управление заданиями</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Button leftIcon={<AddIcon />} onClick={() => handleOpenEditModal()} mb={4}>
              Добавить задание
            </Button>
            <VStack align="stretch" spacing={4}>
              {tasks.map((task) => (
                <HStack key={task.id} justify="space-between" p={2} borderWidth={1} borderRadius="md">
                  <Text>{task.title}</Text>
                  <HStack>
                    <IconButton
                      icon={<EditIcon />}
                      onClick={() => handleOpenEditModal(task)}
                      aria-label="Edit task"
                    />
                    <IconButton
                      icon={<DeleteIcon />}
                      onClick={() => handleDelete(task.id)}
                      aria-label="Delete task"
                    />
                  </HStack>
                </HStack>
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCloseAndUpdate}>Закрыть</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{currentTask ? 'Редактировать задание' : 'Добавить задание'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} as="form" onSubmit={handleSubmit}>
              <FormControl isRequired>
                <FormLabel>Название задания</FormLabel>
                <Input
                  value={taskData.title}
                  onChange={(e) => setTaskData({ ...taskData, title: e.target.value })}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Описание</FormLabel>
                <Textarea
                  value={taskData.description}
                  onChange={(e) => setTaskData({ ...taskData, description: e.target.value })}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Ссылка Notion</FormLabel>
                <Input
                  value={taskData.link_notion}
                  onChange={(e) => setTaskData({ ...taskData, link_notion: e.target.value })}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Ссылка Git</FormLabel>
                <Input
                  value={taskData.link_git}
                  onChange={(e) => setTaskData({ ...taskData, link_git: e.target.value })}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="brand" mr={3} onClick={handleSubmit}>
              {currentTask ? 'Сохранить' : 'Добавить'}
            </Button>
            <Button variant="ghost" onClick={onEditClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ManageTasks;