import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  VStack,
  Container,
  Button,
  HStack,
  Badge,
  Flex,
  Spinner,
} from '@chakra-ui/react';

import { FaCode, FaBook, FaClipboardCheck } from 'react-icons/fa';
import api from '../../utils/api';
import { toast } from '../../utils/toast';
import DOMPurify from 'dompurify';
import { processNotionContent, notionContentStyles } from '../../utils/notionContentProcessor';
import Loader from '../Loader';
import Breadcrumbs from '../Breadcrumbs';

const LessonPage = () => {
  const { courseId, lessonId } = useParams();
  const navigate = useNavigate();
  const [lesson, setLesson] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchLessonContent();
  }, [courseId, lessonId]);

  const fetchLessonContent = async () => {
    try {
      const response = await api.get(`/courses/${courseId}/lessons/${lessonId}/content`, { withCredentials: true });
      setLesson(response.data);
      await fetchLessonTasks();
    } catch (error) {
      console.error('Error fetching lesson content:', error);
      toast({
        title: "Ошибка",
        description: "Не удалось загрузить содержимое урока",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLessonTasks = async () => {
    try {
      const response = await api.get(`/courses/${courseId}/lessons/${lessonId}/tasks`, { withCredentials: true });
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching lesson tasks:', error);
      toast({
        title: "Ошибка",
        description: "Не удалось загрузить задания урока",
        status: "error",
      });
    }
  };

  const handleTaskClick = (taskId) => {
    navigate(`/courses/${courseId}/lessons/${lessonId}/tasks/${taskId}`);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!lesson) {
    return (
      <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
        Урок не найден
      </Box>
    );
  }

  const processedContent = lesson.content ? processNotionContent(lesson.content) : null;

  return (
    <Container maxW="container.lg" py={8}>
      <Breadcrumbs
        items={[
          { label: 'Панель управления', href: '/dashboard' },
          { label: 'Курс', href: `/courses/${courseId}` },
          { label: lesson.title },
        ]}
      />

      <VStack spacing={6} align="stretch">
        <Heading as="h1" size="xl" mb={4}>{lesson.title}</Heading>
        {processedContent ? (
          <Box 
            className="lesson-content"
            sx={notionContentStyles}
          >
            {processedContent}
          </Box>
        ) : (
          <Text>Содержимое урока недоступно</Text>
        )}

        <Heading as="h2" size="lg" mt={8} mb={4}>Задания</Heading>
        {tasks.length > 0 ? (
          <VStack align="stretch" spacing={4}>
            {tasks.map(task => (
              <Box 
                key={task.id} 
                borderWidth={1} 
                borderRadius="md" 
                p={4} 
                cursor="pointer" 
                onClick={() => handleTaskClick(task.id)}
                _hover={{ bg: "gray.50" }}
              >
                <Flex align="center" mb={2}>
                  {task.type === 'code' && <FaCode color="blue" />}
                  {task.type === 'theory' && <FaBook color="purple" />}
                  <Text fontWeight="bold" ml={2}>{task.title}</Text>
                </Flex>
                <Text>{task.description}</Text>
                <Badge colorScheme={task.status === 'completed' ? 'green' : 'orange'} mt={2}>
                  {task.status === 'completed' ? 'Выполнено' : 'Не выполнено'}
                </Badge>
              </Box>
            ))}
          </VStack>
        ) : (
          <Text>В этом уроке нет заданий.</Text>
        )}
      </VStack>
    </Container>
  );
};

export default LessonPage;