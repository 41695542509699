import axios from 'axios';

const api = axios.create({
  baseURL: 'https://lms.edifiels.ru/api',
  withCredentials: true
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (process.env.NODE_ENV !== 'production') {
      console.error('API Error:', error);
    }
    
    if (error.response && error.response.status === 401) {
      return Promise.resolve({ data: null });
    }
    
    return Promise.reject(error);
  }
);

export default api;
