import React from 'react';
import { Spinner } from '@chakra-ui/react';

const SmallLoader = () => {
  return (
    <Spinner
      thickness="2px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="md"
    />
  );
};

export default SmallLoader;