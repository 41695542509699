import React from 'react';
import { Box, Text } from '@chakra-ui/react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Caught an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box padding={4} bg="red.100" color="red.800" borderRadius="md">
          <Text fontWeight="bold">Что-то пошло не так.</Text>
          <Text>Пожалуйста, обновите страницу или попробуйте позже.</Text>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;