import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import api from '../../utils/api';
import { toast } from '../../utils/toast';

const AddCourse = ({ isOpen, onClose, onCourseAdded }) => {
  const [courseData, setCourseData] = useState({ name: '', link_notion: '', link_git: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/courses/add', courseData, { withCredentials: true });
      toast({
        title: "Курс добавлен",
        description: "Новый курс успешно добавлен",
        status: "success",
      });
      onCourseAdded(response.data);
      onClose();
    } catch (error) {
      toast({
        title: "Ошибка",
        description: "Не удалось добавить курс",
        status: "error",
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Добавить новый курс</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} as="form" onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel>Название курса</FormLabel>
              <Input
                value={courseData.name}
                onChange={(e) => setCourseData({ ...courseData, name: e.target.value })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Ссылка Notion</FormLabel>
              <Input
                value={courseData.link_notion}
                onChange={(e) => setCourseData({ ...courseData, link_notion: e.target.value })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Ссылка Git</FormLabel>
              <Input
                value={courseData.link_git}
                onChange={(e) => setCourseData({ ...courseData, link_git: e.target.value })}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brand" mr={3} onClick={handleSubmit}>
            Добавить
          </Button>
          <Button variant="ghost" onClick={onClose}>Отмена</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddCourse;