import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Login from './Auth/Login';
import Register from './Auth/Register';
import Dashboard from './Dashboard';
import Profile from './Auth/Profile';
import CourseDetails from './Course/CourseDetails';
import LessonPage from './Lessons/LessonPage';
import TaskPage from './Task/TaskPage';
import Loader from './Loader';
import CourseStatistics from './Course/CourseStatistics';
import AdminPanel from './Admin/AdminPanel';

const AppRoutes = () => {
  const { user, loading, hasRole } = useAuth();

  if (loading) {
    return <Loader />;
  }

  // Public routes accessible to all users
  const publicRoutes = [
    { path: '/login', element: <Login /> },
    { path: '/register', element: <Register /> },
  ];

  // Private routes with role-based access
  const privateRoutes = [
    { path: '/dashboard', element: <Dashboard />, roles: ['student', 'teacher', 'admin'] },
    { path: '/profile', element: <Profile />, roles: ['student', 'teacher', 'admin'] },
    { path: '/courses/:courseId', element: <CourseDetails />, roles: ['student', 'teacher', 'admin'] },
    { path: '/courses/:courseId/lessons/:lessonId', element: <LessonPage />, roles: ['student', 'teacher', 'admin'] },
    { path: '/courses/:courseId/lessons/:lessonId/tasks/:taskId', element: <TaskPage />, roles: ['student', 'teacher', 'admin'] },
    { path: '/courses/:courseId/statistics', element: <CourseStatistics />, roles: ['teacher', 'admin'] },
    { path: '/admin', element: <AdminPanel />, roles: ['admin'] },

  ];

  const renderRoutes = () => {
    if (!user) {
      // Render public routes for non-authenticated users
      return (
        <>
          {publicRoutes.map(route => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </>
      );
    } else {
      // Render private routes for authenticated users
      return (
        <>
          {privateRoutes.map(route => (
            <Route
              key={route.path}
              path={route.path}
              element={
                hasRole(route.roles) ? (
                  route.element
                ) : (
                  <Navigate to="/dashboard" replace />
                )
              }
            />
          ))}
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </>
      );
    }
  };

  return <Routes>{renderRoutes()}</Routes>;
};

export default AppRoutes;