import React from 'react';
import { Box, Flex, Button, Image, Input, Menu, MenuButton, MenuList, MenuItem, Avatar, HStack, Text, VStack } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const translateRole = (role) => {
  const translations = {
    'admin': 'Администратор',
    'teacher': 'Преподаватель',
    'student': 'Студент',
  };
  return translations[role] || role;
};

const Navbar = () => {
  const { user, logout, hasRole } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const userRole = user && user.roles && user.roles.length > 0 
    ? translateRole(user.roles[0].role_name)
    : 'Пользователь';

  const userGroup = user && user.student_info && user.student_info.group && user.student_info.group.group_name
    ? `(${user.student_info.group.group_name})`
    : null;

  return (
    <Box bg="white" px={4} shadow="md">
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <RouterLink to="/dashboard">
          <Image src="/assets/images/logo.png" alt="LLMS" h={8} cursor="pointer" width="64px" height="64px"/>
        </RouterLink>
{/*         
        {user ? (
          <Input placeholder="Поиск материалов" w="300px" />
        ) : (
          <div></div>
        )}
         */}
        {user ? (
          <Flex alignItems="center">
            <HStack spacing={4} mr={4}>
              <Button
                as={RouterLink}
                to="/dashboard"
                variant="ghost"
                colorScheme="brand"
                _hover={{ bg: 'brand.100' }}
              >
                Мое обучение
              </Button>
              <Button
                as={RouterLink}
                to="/knowledge"
                variant="ghost"
                colorScheme="brand"
                _hover={{ bg: 'brand.100' }}
              >
                База знаний
              </Button>
              {hasRole(['admin']) && (
                <Button
                  as={RouterLink}
                  to="/admin"
                  variant="ghost"
                  colorScheme="brand"
                  _hover={{ bg: 'brand.100' }}
                >
                  Администрирование
                </Button>
              )}
            </HStack>
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}
                rightIcon={<ChevronDownIcon />}
              >
                <HStack>
                  <Avatar 
                    size="sm" 
                    name={`${user.first_name} ${user.last_name}`} 
                    src={user.avatar_url} 
                  />
                  <VStack
                    display={{ base: 'none', md: 'flex' }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2"
                  >
                    <Text fontSize="sm">{user.first_name} {user.last_name}</Text>
                    <HStack spacing={2}>
                      <Text fontSize="xs" color="gray.600">
                        {userRole}
                      </Text>
                      {userGroup && (
                        <Text fontSize="xs" color="green.500">
                          {userGroup}
                        </Text>
                      )}
                    </HStack>
                  </VStack>
                </HStack>
              </MenuButton>
              <MenuList>
                <MenuItem as={RouterLink} to="/profile">
                  <VStack align="start">
                    <Text fontSize="sm">Мой профиль</Text>
                  </VStack>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <VStack align="start">
                    <Text fontSize="sm">Выйти</Text>
                  </VStack>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        ) : (
          <Button colorScheme="brand" as={RouterLink} to="/login">Войти</Button>
        )}
      </Flex>
    </Box>
  );
};

export default Navbar;