import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import api from '../../utils/api';
import { toast } from '../../utils/toast';

const EditGroup = ({ isOpen, onClose, group, onGroupEdited }) => {
  const [groupName, setGroupName] = useState('');

  useEffect(() => {
    if (group) {
      setGroupName(group.group_name);
    }
  }, [group]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.put(`/students/groups/${group.id}`, {
        group_name: groupName
      }, { withCredentials: true });
      toast({
        title: "Группа обновлена",
        description: "Группа успешно обновлена",
        status: "success",
      });
      onGroupEdited(response.data);
      onClose();
    } catch (error) {
      toast({
        title: "Ошибка",
        description: "Не удалось обновить группу",
        status: "error",
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Редактировать группу</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} as="form" onSubmit={handleSubmit}>
            <FormControl isRequired>
              <FormLabel>Название группы</FormLabel>
              <Input
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brand" mr={3} onClick={handleSubmit}>
            Сохранить
          </Button>
          <Button variant="ghost" onClick={onClose}>Отмена</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditGroup;