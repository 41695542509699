import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
} from '@chakra-ui/react';

const EditLesson = ({ isOpen, onClose, lesson, onEditLesson }) => {
  const [lessonData, setLessonData] = useState({ title: '', description: '', link_notion: '', link_git: '' });

  useEffect(() => {
    if (lesson) {
      setLessonData({
        title: lesson.title || '',
        description: lesson.description || '',
        link_notion: lesson.link_notion || '',
        link_git: lesson.link_git || '',
      });
    }
  }, [lesson]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onEditLesson(lessonData);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Редактировать урок</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} as="form" onSubmit={handleSubmit}>
            <FormControl isRequired>
              <FormLabel>Название урока</FormLabel>
              <Input
                value={lessonData.title}
                onChange={(e) => setLessonData({ ...lessonData, title: e.target.value })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Описание</FormLabel>
              <Textarea
                value={lessonData.description}
                onChange={(e) => setLessonData({ ...lessonData, description: e.target.value })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Ссылка Notion</FormLabel>
              <Input
                value={lessonData.link_notion}
                onChange={(e) => setLessonData({ ...lessonData, link_notion: e.target.value })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Ссылка Git</FormLabel>
              <Input
                value={lessonData.link_git}
                onChange={(e) => setLessonData({ ...lessonData, link_git: e.target.value })}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brand" mr={3} onClick={handleSubmit}>
            Сохранить
          </Button>
          <Button variant="ghost" onClick={onClose}>Отмена</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditLesson;