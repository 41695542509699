import React from 'react';
import {
  Box,
  Heading,
  SimpleGrid,
  IconButton,
  Text,
  VStack,
  HStack,
  Badge,
  Wrap,
  WrapItem,
  Link,
  Avatar,
  Tooltip,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { BarChartIcon } from './../../icons/BarChartIcon';


const CourseList = ({ courses, onEditCourse, onDeleteCourse }) => {
  const { hasRole } = useAuth();
  const navigate = useNavigate();

  const handleCourseClick = (courseId) => {
    navigate(`/courses/${courseId}`);
  };

  const renderTeacherName = (teacher) => {
    if (teacher && teacher.first_name && teacher.last_name) {
      return `${teacher.first_name} ${teacher.last_name}`;
    } else if (teacher && teacher.login) {
      return teacher.login;
    } else {
      return 'Имя преподавателя не указано';
    }
  };

  if (!Array.isArray(courses) || courses.length === 0) {
    return (
      <Box>
        <Heading size="md" mb={4}>Мои курсы</Heading>
        <Text>У вас пока нет доступных курсов.</Text>
      </Box>
    );
  }

  return (
    <Box>
      <Heading size="md" mb={4}>Мои курсы</Heading>
      <SimpleGrid columns={[1, 2, 3]} spacing={4}>
        {courses.map(course => (
          <Box
            key={course.id}
            p={4}
            borderWidth={1}
            borderRadius="md"
            position="relative"
            boxShadow="md"
            onClick={() => handleCourseClick(course.id)}
            cursor="pointer"
            _hover={{ boxShadow: "lg" }}
          >
            <VStack align="stretch" spacing={3}>
              <Heading size="sm">{course.name}</Heading>

              {course.link_notion && (
                <Link href={course.link_notion} isExternal color="blue.500" onClick={(e) => e.stopPropagation()}>
                  Notion <ExternalLinkIcon mx="2px" />
                </Link>
              )}
              {course.link_git && (
                <Link href={course.link_git} isExternal color="blue.500" onClick={(e) => e.stopPropagation()}>
                  GitHub <ExternalLinkIcon mx="2px" />
                </Link>
              )}

              {course.groups && course.groups.length > 0 && (
                <Box>
                  <Text fontSize="xs" fontWeight="bold" mb={1}>Группы:</Text>
                  <Wrap>
                    {course.groups.map(group => (
                      <WrapItem key={group.id}>
                        <Badge colorScheme="green">{group.group_name}</Badge>
                      </WrapItem>
                    ))}
                  </Wrap>
                </Box>
              )}

              {course.teachers && course.teachers.length > 0 && (
                <Box>
                  <Text fontSize="xs" fontWeight="bold" mb={1}>Преподаватели:</Text>
                  <HStack spacing={2}>
                    {course.teachers.map(teacher => (
                      <HStack key={teacher.id} spacing={2} align="center">
                        <Avatar
                          size="sm"
                          name={renderTeacherName(teacher)}
                          src={teacher.avatar_url}
                        />
                        <Text fontSize="sm">
                          {renderTeacherName(teacher)}
                        </Text>
                      </HStack>
                    ))}
                  </HStack>
                </Box>

              )}
            </VStack>

            {hasRole(['teacher', 'admin']) && (
              <HStack position="absolute" top="2" right="2">
                <IconButton
                  icon={<BarChartIcon />}
                  size="sm"
                  aria-label="Course Statistics"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/courses/${course.id}/statistics`);
                  }}
                />
                <IconButton
                  icon={<EditIcon />}
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditCourse(course);
                  }}
                  aria-label="Edit course"
                />
                <IconButton
                  icon={<DeleteIcon />}
                  size="sm"
                  colorScheme="red"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteCourse(course.id);
                  }}
                  aria-label="Delete course"
                />
              </HStack>
            )}
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default CourseList;