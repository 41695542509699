import { createStandaloneToast } from '@chakra-ui/toast'

const { ToastContainer, toast } = createStandaloneToast({
  defaultOptions: {
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  }
})

export { ToastContainer, toast }