import React from 'react';
import {
    Box,
    Heading,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
} from '@chakra-ui/react';
import UserManagement from './UserManagement';
import RoleManagement from './RoleManagement';
import GroupManagement from './GroupManagement';
import ErrorBoundary from './ErrorBoundary';
import StudentFileManager from './StudentFileManager';

const AdminPanel = () => {
    return (
        <ErrorBoundary>
            <Box maxWidth="1200px" margin="auto" mt={8} p={4}>
                <Heading mb={4}>Панель администратора</Heading>
                <Tabs>
                    <TabList>
                        <Tab>Пользователи</Tab>
                        <Tab>Роли</Tab>
                        <Tab>Управление группами</Tab>
                        <Tab>Управление студентами</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <ErrorBoundary>
                                <UserManagement />
                            </ErrorBoundary>
                        </TabPanel>
                        <TabPanel>
                            <ErrorBoundary>
                                <RoleManagement />
                            </ErrorBoundary>
                        </TabPanel>
                        <TabPanel>
                            <ErrorBoundary>
                                <GroupManagement />
                            </ErrorBoundary>
                        </TabPanel>
                        <TabPanel>
                            <ErrorBoundary>
                                <StudentFileManager />
                            </ErrorBoundary>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </ErrorBoundary>
    );
};

export default AdminPanel;