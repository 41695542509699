import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  Button,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Heading,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import api from '../../utils/api';
import { toast } from '../../utils/toast';

const StudentList = ({ groupId, groupName }) => {
  const [students, setStudents] = useState([]);
  const [availableStudents, setAvailableStudents] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedStudent, setSelectedStudent] = useState('');
  const [studentToRemove, setStudentToRemove] = useState(null);
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const cancelRef = React.useRef();

  useEffect(() => {
    fetchStudents();
    fetchAvailableStudents();
  }, [groupId]);

  const fetchStudents = async () => {
    try {
      const response = await api.get(`/students/groups/${groupId}/students`, { withCredentials: true });
      setStudents(response.data);
    } catch (error) {
      toast({
        title: "Ошибка",
        description: "Не удалось загрузить список студентов",
        status: "error",
      });
    }
  };

  const fetchAvailableStudents = async () => {
    try {
      const response = await api.get('/students/available', { withCredentials: true });
      setAvailableStudents(response.data);
    } catch (error) {
      toast({
        title: "Ошибка",
        description: "Не удалось загрузить список доступных студентов",
        status: "error",
      });
    }
  };

  const handleAddStudent = async () => {
    try {
      await api.post(`/students/groups/${groupId}/add_student`, { student_id: selectedStudent }, { withCredentials: true });
      toast({
        title: "Студент добавлен",
        description: "Студент успешно добавлен в группу",
        status: "success",
      });
      fetchStudents();
      fetchAvailableStudents();
      onClose();
    } catch (error) {
      toast({
        title: "Ошибка",
        description: "Не удалось добавить студента в группу",
        status: "error",
      });
    }
  };

  const handleRemoveStudent = async () => {
    try {
      await api.delete(`/students/groups/${groupId}/remove_student/${studentToRemove.id}`, { withCredentials: true });
      toast({
        title: "Студент удален из группы",
        description: "Студент успешно удален из группы",
        status: "success",
      });
      fetchStudents();
      fetchAvailableStudents();
      onAlertClose();
    } catch (error) {
      toast({
        title: "Ошибка",
        description: "Не удалось удалить студента из группы",
        status: "error",
      });
    }
  };

  return (
    <Box>
      <Heading size="md" mb={4}>Студенты группы {groupName}</Heading>
      <Button onClick={onOpen} colorScheme="brand" mb={4}>Добавить студента в группу</Button>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Фото</Th>
            <Th>Имя</Th>
            <Th>Фамилия</Th>
            <Th>Логин</Th>
            <Th>Год поступления</Th>
            <Th>Курс</Th>
            <Th>Действия</Th>
          </Tr>
        </Thead>
        <Tbody>
          {students.map(student => (
            <Tr key={student.id}>
              <Td>
                <Avatar name={`${student.first_name} ${student.last_name}`} src={student.avatar_url} />
              </Td>
              <Td>{student.first_name}</Td>
              <Td>{student.last_name}</Td>
              <Td>{student.login}</Td>
              <Td>{student.enrollment_year}</Td>
              <Td>{student.course}</Td>
              <Td>
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={() => {
                    setStudentToRemove(student);
                    onAlertOpen();
                  }}
                  aria-label="Remove student from group"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Добавить студента в группу</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Выберите студента</FormLabel>
              <Select
                placeholder="Выберите студента"
                value={selectedStudent}
                onChange={(e) => setSelectedStudent(e.target.value)}
              >
                {availableStudents.map(student => (
                  <option key={student.id} value={student.id}>
                    {student.first_name} {student.last_name} ({student.login})
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="brand" mr={3} onClick={handleAddStudent}>
              Добавить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Удалить студента из группы
            </AlertDialogHeader>

            <AlertDialogBody>
              Вы уверены, что хотите удалить студента {studentToRemove?.first_name} {studentToRemove?.last_name} из группы?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onAlertClose}>
                Отмена
              </Button>
              <Button colorScheme="red" onClick={handleRemoveStudent} ml={3}>
                Удалить
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default StudentList;