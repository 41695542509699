import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  Button,
  IconButton,
  useDisclosure,
  Collapse,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import api from '../../utils/api';
import { toast } from '../../utils/toast';
import AddGroup from './AddGroup';
import EditGroup from './EditGroup';
import StudentList from './StudentList';

const GroupList = () => {
  const [groups, setGroups] = useState([]);
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const cancelRef = React.useRef();

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = async () => {
    try {
      const response = await api.get('/students/groups', { withCredentials: true });
      setGroups(response.data);
    } catch (error) {
      toast({
        title: "Ошибка",
        description: "Не удалось загрузить группы",
        status: "error",
      });
    }
  };

  const handleGroupAdded = (newGroup) => {
    setGroups([...groups, newGroup]);
  };

  const handleGroupEdited = (updatedGroup) => {
    setGroups(groups.map(group => group.id === updatedGroup.id ? updatedGroup : group));
  };

  const handleEditClick = (group) => {
    setSelectedGroup(group);
    onEditOpen();
  };

  const handleDeleteClick = (group) => {
    setGroupToDelete(group);
    onAlertOpen();
  };

  const confirmDeleteGroup = async () => {
    try {
      await api.delete(`/students/groups/${groupToDelete.id}`, { withCredentials: true });
      setGroups(groups.filter(group => group.id !== groupToDelete.id));
      toast({
        title: "Группа удалена",
        description: "Группа успешно удалена",
        status: "success",
      });
      onAlertClose();
    } catch (error) {
      toast({
        title: "Ошибка",
        description: "Не удалось удалить группу",
        status: "error",
      });
    }
  };

  const toggleGroupExpansion = (groupId) => {
    setExpandedGroup(expandedGroup === groupId ? null : groupId);
  };

  return (
    <Box>
      <Button onClick={onAddOpen} colorScheme="brand" mb={4}>Добавить группу</Button>
      <VStack spacing={4} align="stretch">
        {groups.map(group => (
          <Box key={group.id}>
            <HStack justify="space-between" p={4} borderWidth={1} borderRadius="md">
              <VStack align="start" spacing={1}>
                <Text fontWeight="bold">{group.group_name}</Text>
                <Text fontSize="sm">Студентов: {group.student_count}</Text>
              </VStack>
              <HStack>
                <IconButton
                  icon={expandedGroup === group.id ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  onClick={() => toggleGroupExpansion(group.id)}
                  aria-label="Toggle student list"
                />
                <IconButton
                  icon={<EditIcon />}
                  onClick={() => handleEditClick(group)}
                  aria-label="Edit group"
                />
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={() => handleDeleteClick(group)}
                  aria-label="Delete group"
                />
              </HStack>
            </HStack>
            <Collapse in={expandedGroup === group.id}>
              <Box mt={2} ml={4}>
                <StudentList groupId={group.id} groupName={group.group_name} />
              </Box>
            </Collapse>
          </Box>
        ))}
      </VStack>
      <AddGroup isOpen={isAddOpen} onClose={onAddClose} onGroupAdded={handleGroupAdded} />
      <EditGroup
        isOpen={isEditOpen}
        onClose={onEditClose}
        group={selectedGroup}
        onGroupEdited={handleGroupEdited}
      />
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Удалить группу
            </AlertDialogHeader>

            <AlertDialogBody>
              Вы уверены, что хотите удалить группу {groupToDelete?.group_name}? Это действие нельзя отменить.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onAlertClose}>
                Отмена
              </Button>
              <Button colorScheme="red" onClick={confirmDeleteGroup} ml={3}>
                Удалить
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default GroupList;