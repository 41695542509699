import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Select,
  VStack,
  Heading,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Avatar,
  HStack,
  Text,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import api from '../../utils/api';
import { toast } from '../../utils/toast';
import SmallLoader from './../SmallLoader';
import { SolutionEditor, SolutionHistoryAccordion, SolutionUpdate, SolutionOnlyStatus, getStatusColor, FileDownloadLink } from './SolutionViewer';

const TeacherSolutionsList = ({ courseId, lessonId, taskId }) => {
  const [solutions, setSolutions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSolution, setSelectedSolution] = useState(null);
  const [solutionHistory, setSolutionHistory] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isHistoryOpen, onOpen: onHistoryOpen, onClose: onHistoryClose } = useDisclosure();

  useEffect(() => {
    fetchSolutions();
  }, [courseId, lessonId, taskId]);

  const fetchSolutions = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/courses/${courseId}/lessons/${lessonId}/tasks/${taskId}/solutions`);
      setSolutions(response.data);
    } catch (error) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось загрузить решения студентов.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = async (solutionId, newStatus) => {
    try {
      await api.put(`/courses/${courseId}/lessons/${lessonId}/tasks/${taskId}/solutions/${solutionId}/status`, { status: newStatus });
      fetchSolutions();
      toast({
        title: 'Статус обновлен',
        description: 'Статус решения успешно обновлен.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось обновить статус решения.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const openSolutionModal = (solution) => {
    setSelectedSolution(solution);
    onOpen();
  };

  const fetchSolutionHistory = async (studentId) => {
    try {
      const response = await api.get(`/courses/${courseId}/lessons/${lessonId}/tasks/${taskId}/solution/history`, { params: { student_id: studentId } });
      setSolutionHistory(response.data);
      onHistoryOpen();
    } catch (error) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось загрузить историю решений.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return (
      <Box p={4} borderWidth="1px" borderRadius="lg">
        <VStack align="center" spacing={4}>
          <Text>Загрузка решений студентов...</Text>
          <SmallLoader />
        </VStack>
      </Box>
    );
  }

  return (
    <Box>
      <Heading size="md" mb={4}>Решения студентов</Heading>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Студент</Th>
            <Th>Решение</Th>
            <Th>Файл</Th>
            <Th>Статус</Th>
            <Th>Последнее обновление</Th>
            <Th>Действия</Th>
          </Tr>
        </Thead>
        <Tbody>
          {solutions.map((solution) => (
            <Tr key={solution.id}>
              <Td>
                <HStack>
                  <Avatar 
                    size="sm" 
                    name={`${solution.student.first_name} ${solution.student.last_name}`} 
                    src={solution.student.avatar_url}
                  />
                  <VStack align="start" spacing={0}>
                    <Text fontWeight="bold">{`${solution.student.last_name} ${solution.student.first_name}`}</Text>
                    <Text fontSize="sm" color="gray.500">{solution.student.login}</Text>
                  </VStack>
                </HStack>
              </Td>
              <Td>
                <Button size="sm" onClick={() => openSolutionModal(solution)}>
                  Просмотреть решение
                </Button>
              </Td>
              <Td>
                <FileDownloadLink filePath={solution.file_path} />
              </Td>
              <Td>
                <SolutionOnlyStatus status={solution.status} />
              </Td>
              <Td>
                <SolutionUpdate updatedAt={solution.updated_at} />
              </Td>
              <Td>
                <Button
                  size="sm"
                  colorScheme={solution.status === 'Зачтено' ? 'red' : 'green'}
                  onClick={() => handleStatusChange(solution.id, solution.status === 'Зачтено' ? 'Не зачтено' : 'Зачтено')}
                  mr={2}
                  marginBottom="2"
                >
                  {solution.status === 'Зачтено' ? 'Не зачтено' : 'Зачтено'}
                </Button>
                <Button
                  size="sm"
                  onClick={() => fetchSolutionHistory(solution.student.id)}
                >
                  История
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>


      <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Решение студента</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {selectedSolution && (
            <VStack align="start" spacing={4}>
              <SolutionEditor solution={selectedSolution.solution_text} />
              {selectedSolution.error_message && (
                <Box width="100%">
                  <Text fontWeight="bold" color="red.500">Сообщение об ошибке:</Text>
                  <Box 
                    bg="red.50" 
                    p={2} 
                    borderRadius="md" 
                    borderColor="red.200"
                    borderWidth="1px"
                    maxHeight="150px" 
                    overflowY="auto"
                  >
                    <pre>{selectedSolution.error_message}</pre>
                  </Box>
                </Box>
              )}
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Закрыть
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

    <Modal isOpen={isHistoryOpen} onClose={onHistoryClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>История решений</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SolutionHistoryAccordion solutionHistory={solutionHistory} />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onHistoryClose}>
            Закрыть
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </Box>
);

};

export default TeacherSolutionsList;