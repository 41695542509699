import React, { useState, useEffect } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    HStack,
} from '@chakra-ui/react';
import api from '../../utils/api';

const RoleManagement = () => {
    const [roles, setRoles] = useState([]);
    const [newRole, setNewRole] = useState({ role_name: '' });
    const [editingRole, setEditingRole] = useState(null);
    const [isAddRoleModalOpen, setIsAddRoleModalOpen] = useState(false);
    const [isEditRoleModalOpen, setIsEditRoleModalOpen] = useState(false);
    const [isDeleteRoleModalOpen, setIsDeleteRoleModalOpen] = useState(false);
    const [deletingRoleId, setDeletingRoleId] = useState(null);
    const toast = useToast();

    useEffect(() => {
        fetchRoles();
    }, []);

    const fetchRoles = async () => {
        try {
            const response = await api.get('/admin/roles', { withCredentials: true });
            setRoles(response.data);
        } catch (error) {
            toast({
                title: "Ошибка",
                description: "Не удалось загрузить список ролей",
                status: "error",
            });
        }
    };

    const handleAddRole = async () => {
        try {
            await api.post('/admin/roles', newRole, { withCredentials: true });
            fetchRoles();
            setIsAddRoleModalOpen(false);
            setNewRole({ role_name: '' });
            toast({
                title: "Успех",
                description: "Новая роль добавлена",
                status: "success",
            });
        } catch (error) {
            toast({
                title: "Ошибка",
                description: "Не удалось добавить новую роль",
                status: "error",
            });
        }
    };

    const handleUpdateRole = async () => {
        try {
            await api.put(`/admin/roles/${editingRole.id}`, { role_name: editingRole.role_name }, { withCredentials: true });
            fetchRoles();
            setIsEditRoleModalOpen(false);
            setEditingRole(null);
            toast({
                title: "Успех",
                description: "Роль обновлена",
                status: "success",
            });
        } catch (error) {
            toast({
                title: "Ошибка",
                description: "Не удалось обновить роль",
                status: "error",
            });
        }
    };

    const handleDeleteRole = async () => {
        try {
            await api.delete(`/admin/roles/${deletingRoleId}`, { withCredentials: true });
            fetchRoles();
            setIsDeleteRoleModalOpen(false);
            toast({
                title: "Успех",
                description: "Роль удалена",
                status: "success",
            });
        } catch (error) {
            toast({
                title: "Ошибка",
                description: "Не удалось удалить роль",
                status: "error",
            });
        }
    };

    return (
        <>
            <Button onClick={() => setIsAddRoleModalOpen(true)} colorScheme="green" mb={4}>
                Добавить роль
            </Button>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Название роли</Th>
                        <Th>Действия</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {roles.map((role) => (
                        <Tr key={role.id}>
                            <Td>{role.id}</Td>
                            <Td>{role.role_name}</Td>
                            <Td>
                                <HStack>
                                    <Button onClick={() => { setEditingRole(role); setIsEditRoleModalOpen(true); }} size="sm">
                                        Редактировать
                                    </Button>
                                    <Button onClick={() => {
                                        setDeletingRoleId(role.id);
                                        setIsDeleteRoleModalOpen(true);
                                    }} colorScheme="red" size="sm">
                                        Удалить
                                    </Button>
                                </HStack>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            {/* Модальное окно для добавления роли */}
            <Modal isOpen={isAddRoleModalOpen} onClose={() => setIsAddRoleModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Добавить новую роль</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Название роли</FormLabel>
                            <Input
                                value={newRole.role_name}
                                onChange={(e) => setNewRole({ ...newRole, role_name: e.target.value })}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleAddRole}>
                            Добавить
                        </Button>
                        <Button variant="ghost" onClick={() => setIsAddRoleModalOpen(false)}>Отмена</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Модальное окно для редактирования роли */}
            <Modal isOpen={isEditRoleModalOpen} onClose={() => setIsEditRoleModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Редактировать роль</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Название роли</FormLabel>
                            <Input
                                value={editingRole?.role_name || ''}
                                onChange={(e) => setEditingRole({ ...editingRole, role_name: e.target.value })}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleUpdateRole}>
                            Обновить
                        </Button>
                        <Button variant="ghost" onClick={() => setIsEditRoleModalOpen(false)}>Отмена</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Модальное окно для подтверждения удаления роли */}
            <Modal isOpen={isDeleteRoleModalOpen} onClose={() => setIsDeleteRoleModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Подтверждение удаления</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Вы уверены, что хотите удалить эту роль?
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} onClick={handleDeleteRole}>
                            Удалить
                        </Button>
                        <Button variant="ghost" onClick={() => setIsDeleteRoleModalOpen(false)}>Отмена</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default RoleManagement;