import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  VStack,
  HStack,
  Input,
  useToast,
  Icon,
  Text,
} from '@chakra-ui/react';
import { FaUpload } from 'react-icons/fa';
import api from '../../utils/api';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

const SubmitSolution = ({ courseId, lessonId, taskId }) => {
  const [solution, setSolution] = useState('');
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData();
    if (solution) formData.append('solution', solution);
    if (file) formData.append('file', file);

    try {
      await api.post(`/courses/${courseId}/lessons/${lessonId}/tasks/${taskId}/submit`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast({
        title: 'Решение отправлено',
        description: 'Ваше решение успешно отправлено и ожидает проверки.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setSolution('');
      setFile(null);
    } catch (error) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.detail || 'Не удалось отправить решение. Попробуйте еще раз.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size > MAX_FILE_SIZE) {
      toast({
        title: 'Ошибка',
        description: 'Размер файла превышает допустимый лимит в 5 MB',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      e.target.value = null;
    } else {
      setFile(selectedFile);
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <VStack spacing={4} align="stretch">
        <FormControl>
          <FormLabel>Ваше решение</FormLabel>
          <AceEditor
            mode="python"
            theme="monokai"
            value={solution}
            onChange={setSolution}
            name="solution-editor"
            fontSize={16}
            width="100%"
            height="300px"
            editorProps={{ $blockScrolling: true }}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Или загрузите файл с решением (макс. 5 MB)</FormLabel>
          <HStack>
            <Input
              type="file"
              onChange={handleFileChange}
              display="none"
              id="file-upload"
            />
            <Button
              as="label"
              htmlFor="file-upload"
              colorScheme="blue"
              leftIcon={<Icon as={FaUpload} />}
            >
              Выберите файл
            </Button>
            <Text>{file ? file.name : 'Файл не выбран'}</Text>
          </HStack>
        </FormControl>
        <Button 
          type="submit" 
          colorScheme="brand" 
          isLoading={isSubmitting}
          loadingText="Отправка..."
        >
          Отправить решение
        </Button>
      </VStack>
    </Box>
  );
};

export default SubmitSolution;