import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Link,
  useToast,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../utils/api';
import Loader from '../Loader';
import Breadcrumbs from '../Breadcrumbs';
import { processNotionContent, notionContentStyles } from '../../utils/notionContentProcessor';
import SubmitSolution from './SubmitSolution';
import StudentSolution from './StudentSolution';
import TeacherSolutionsList from './TeacherSolutionsList';

const TaskPage = () => {
  const { courseId, lessonId, taskId } = useParams();
  const { user, hasRole } = useAuth();
  const [task, setTask] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [lesson, setLesson] = useState(null);
  const toast = useToast();

  useEffect(() => {
    fetchTaskDetails();
    fetchCourseAndLessonDetails();
  }, [courseId, lessonId, taskId]);

  const fetchCourseAndLessonDetails = async () => {
    try {
      const courseResponse = await api.get(`/courses/${courseId}`, { withCredentials: true });
      setCourse(courseResponse.data);

      const lessonResponse = await api.get(`/courses/${courseId}/lessons/${lessonId}`, { withCredentials: true });
      setLesson(lessonResponse.data);
    } catch (error) {
      console.error('Error fetching course and lesson details:', error);
      toast({
        title: "Ошибка",
        description: "Не удалось загрузить информацию о курсе и уроке",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchTaskDetails = async () => {
    try {
      const response = await api.get(`/courses/${courseId}/lessons/${lessonId}/tasks/${taskId}`, { withCredentials: true });
      setTask(response.data);
    } catch (error) {
      console.error('Error fetching task details:', error);
      toast({
        title: "Ошибка",
        description: "Не удалось загрузить информацию о задании",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!task) {
    return <Box>Задание не найдено</Box>;
  }

  return (
    <Box maxWidth="800px" margin="auto" mt={8} p={4}>
      <Breadcrumbs
        items={[
          { label: 'Панель управления', href: '/dashboard' },
          { label: course?.name || 'Курс', href: `/courses/${courseId}` },
          { label: lesson?.title || 'Урок', href: `/courses/${courseId}/lessons/${lessonId}` },
          { label: task.title },
        ]}
      />
      <VStack align="stretch" spacing={6}>
        <Heading as="h1" size="xl">{task.title}</Heading>
        <Text>{task.description}</Text>
        {task.content && (
          <Box sx={notionContentStyles}>
            {processNotionContent(task.content)}
          </Box>
        )}

        {hasRole(['student']) && (
          <VStack align="stretch" spacing={4}>
            <StudentSolution courseId={courseId} lessonId={lessonId} taskId={taskId} />
            <SubmitSolution courseId={courseId} lessonId={lessonId} taskId={taskId} />
          </VStack>
        )}

        {hasRole(['teacher', 'admin']) && (
          <VStack align="stretch" spacing={4}>
            <Heading as="h2" size="lg">Решения студентов</Heading>
            <TeacherSolutionsList courseId={courseId} lessonId={lessonId} taskId={taskId} />
          </VStack>
        )}

      </VStack>
    </Box>
  );
};

export default TaskPage;