import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  VStack,
  Button,
  Flex,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useAuth } from '../contexts/AuthContext';
import CourseList from './Course/CourseList';
import AddCourse from './Course/AddCourse';
import EditCourse from './Course/EditCourse';
import GroupList from './Group/GroupList';
import api from '../utils/api';
import { toast } from '../utils/toast';

const Dashboard = () => {
  const { user, hasRole } = useAuth();
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const [courses, setCourses] = useState([]);
  const [editingCourse, setEditingCourse] = useState(null);

  useEffect(() => {
    fetchCourses();
  }, [hasRole]);

  const fetchCourses = async () => {
    try {
      let response;
      if (hasRole(['teacher', 'admin'])) {
        response = await api.get('/teacher/courses', { withCredentials: true });
      } else if (hasRole(['student'])) {
        response = await api.get('/students/courses', { withCredentials: true });
      }
      setCourses(response.data);
    } catch (error) {
      console.error('Error fetching courses:', error);
      setCourses([]); // Set courses to an empty array in case of error
      toast({
        title: "Ошибка",
        description: "Не удалось загрузить курсы",
        status: "error",
      });
    }
  };
  const handleCourseAdded = (newCourse) => {
    setCourses(prevCourses => [...prevCourses, newCourse]);
    fetchCourses();
  };

  const handleEditCourse = (course) => {
    setEditingCourse(course);
    onEditOpen();
  };

  const handleCourseEdited = (updatedCourse) => {
    setCourses(prevCourses => prevCourses.map(course => 
      course.id === updatedCourse.id ? updatedCourse : course
    ));
    fetchCourses();
  };

  const handleDeleteCourse = async (courseId) => {
    try {
      await api.delete(`/courses/delete/${courseId}`, { withCredentials: true });
      toast({
        title: "Курс удален",
        description: "Курс успешно удален",
        status: "success",
      });
      fetchCourses();
    } catch (error) {
      toast({
        title: "Ошибка",
        description: "Не удалось удалить курс",
        status: "error",
      });
    }
  };

  return (
    <Box maxWidth="1200px" margin="auto" mt={8} p={4}>
      <VStack spacing={8} align="stretch">
        <Flex justify="space-between" align="center">
          <Heading>Панель управления</Heading>
        </Flex>
        
        <Tabs>
          <TabList>
            <Tab>Курсы</Tab>
            {hasRole(['teacher', 'admin']) && <Tab>Группы</Tab>}
          </TabList>

          <TabPanels>
            <TabPanel>
              {hasRole(['teacher']) && (
                <Button 
                  onClick={onAddOpen} 
                  colorScheme="brand" 
                  size="sm" 
                  leftIcon={<AddIcon />}
                  mb={4}
                >
                  Новый курс
                </Button>
              )}
              <CourseList 
                courses={courses} 
                onEditCourse={handleEditCourse} 
                onDeleteCourse={handleDeleteCourse}
              />
            </TabPanel>
            {hasRole(['teacher', 'admin']) && (
              <TabPanel>
                <GroupList />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>

        <AddCourse isOpen={isAddOpen} onClose={onAddClose} onCourseAdded={handleCourseAdded} />
        <EditCourse 
          isOpen={isEditOpen} 
          onClose={onEditClose} 
          course={editingCourse} 
          onCourseEdited={handleCourseEdited} 
        />
      </VStack>
    </Box>
  );
};

export default Dashboard;

