import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, FormControl, FormLabel, Input, VStack, Heading, Text, Link } from '@chakra-ui/react';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from '../../utils/toast';

const Register = () => {
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    login: '',
    email: '',
    password: '',
    confirm_password: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const { register } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userData.password !== userData.confirm_password) {
      toast({
        title: 'Ошибка',
        description: 'Пароли не совпадают',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    try {
      await register(userData);
      toast({
        title: 'Успешная регистрация',
        description: 'Вы успешно зарегистрировались',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      navigate('/login');
    } catch (error) {
      toast({
        title: 'Ошибка регистрации',
        description: error.response?.data?.detail || 'Произошла ошибка при регистрации',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box maxWidth="400px" margin="auto" mt={8}>
      <VStack spacing={4} as="form" onSubmit={handleSubmit}>
        <Heading>Регистрация</Heading>
        <FormControl isRequired>
          <FormLabel>Имя</FormLabel>
          <Input
            type="text"
            value={userData.first_name}
            onChange={(e) => setUserData({ ...userData, first_name: e.target.value })}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Фамилия</FormLabel>
          <Input
            type="text"
            value={userData.last_name}
            onChange={(e) => setUserData({ ...userData, last_name: e.target.value })}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Логин</FormLabel>
          <Input
            type="text"
            value={userData.login}
            onChange={(e) => setUserData({ ...userData, login: e.target.value })}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            value={userData.email}
            onChange={(e) => setUserData({ ...userData, email: e.target.value })}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Пароль</FormLabel>
          <Input
            type="password"
            value={userData.password}
            onChange={(e) => setUserData({ ...userData, password: e.target.value })}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Подтвердите пароль</FormLabel>
          <Input
            type="password"
            value={userData.confirm_password}
            onChange={(e) => setUserData({ ...userData, confirm_password: e.target.value })}
          />
        </FormControl>
        <Button colorScheme="brand" type="submit" isLoading={isLoading} width="100%">
          Зарегистрироваться
        </Button>
        <Text>
          Уже есть аккаунт? <Link color="brand.500" href="/login">Войти</Link>
        </Text>
      </VStack>
    </Box>
  );
};

export default Register;
