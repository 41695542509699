import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  VStack, 
  Heading, 
  Text, 
  FormControl, 
  FormLabel, 
  Input, 
  Button, 
  useToast, 
  Avatar, 
  Center, 
  IconButton,
  Flex
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { useAuth } from '../../contexts/AuthContext';
import Breadcrumbs from '../Breadcrumbs';
import api from '../../utils/api';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

const Profile = () => {
  const { user, checkAuth } = useAuth();
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    new_password: '',
    confirm_password: '',
  });
  const [avatar, setAvatar] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const fileInputRef = useRef();

  useEffect(() => {
    if (user) {
      setFormData({
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        email: user.email || '',
        new_password: '',
        confirm_password: '',
      });
      setAvatarPreview(user.avatar_url);
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleAvatarChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      if (file.size > MAX_FILE_SIZE) {
        toast({
          title: "Ошибка",
          description: "Размер файла превышает допустимый лимит в 5 MB",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      setAvatar(file);
      setAvatarPreview(URL.createObjectURL(file));
    }
  };

  const validateForm = () => {
    if (formData.new_password !== formData.confirm_password) {
      toast({
        title: "Ошибка",
        description: "Пароли не совпадают",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const formDataToSend = new FormData();
    let hasChanges = false;

    if (formData.first_name !== user.first_name) {
      formDataToSend.append('first_name', formData.first_name);
      hasChanges = true;
    }
    if (formData.last_name !== user.last_name) {
      formDataToSend.append('last_name', formData.last_name);
      hasChanges = true;
    }
    if (formData.email !== user.email) {
      formDataToSend.append('email', formData.email);
      hasChanges = true;
    }
    if (formData.new_password) {
      formDataToSend.append('password', formData.new_password);
      hasChanges = true;
    }
    if (avatar) {
      formDataToSend.append('avatar', avatar);
      hasChanges = true;
    }

    if (!hasChanges) {
      toast({
        title: "Нет изменений",
        description: "Вы не внесли изменений в профиль",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      const response = await api.put('/auth/update-profile/', formDataToSend, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      toast({
        title: "Профиль обновлен",
        description: "Ваши данные успешно обновлены",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      await checkAuth();

      if (response.data.user) {
        setFormData(prev => ({
          ...prev,
          first_name: response.data.user.first_name,
          last_name: response.data.user.last_name,
          email: response.data.user.email,
        }));
        setAvatarPreview(response.data.user.avatar_url);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      toast({
        title: "Ошибка",
        description: error.response?.data?.detail || "Не удалось обновить профиль",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box maxW="container.lg" margin="auto" mt={8} p={4}>
      <Breadcrumbs
        items={[
          { label: 'Панель управления', href: '/dashboard' },
          { label: 'Профиль' },
        ]}
      />
      <VStack spacing={4} align="stretch" as="form" onSubmit={handleSubmit}>
        <Heading>Мой профиль / Личные данные</Heading>
        <Center>
          <Box position="relative">
            <Avatar 
              size="2xl" 
              name={`${formData.first_name} ${formData.last_name}`} 
              src={avatarPreview}
              key={avatarPreview}
            />
            <IconButton
              aria-label="Change avatar"
              icon={<EditIcon />}
              size="sm"
              position="absolute"
              bottom="0"
              right="0"
              onClick={() => fileInputRef.current.click()}
            />
            <Input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleAvatarChange}
              display="none"
            />
          </Box>
        </Center>
                <Box>
          <Text fontWeight="bold">Общая информация</Text>
          <FormControl>
            <FormLabel>Имя</FormLabel>
            <Input name="first_name" value={formData.first_name} onChange={handleChange} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Фамилия</FormLabel>
            <Input name="last_name" value={formData.last_name} onChange={handleChange} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Логин</FormLabel>
            <Input value={user?.login} isReadOnly />
          </FormControl>
        </Box>
        <Box>
          <Text fontWeight="bold">Электронная почта</Text>
          <FormControl>
            <FormLabel>E-mail</FormLabel>
            <Input name="email" type="email" value={formData.email} onChange={handleChange} />
          </FormControl>
        </Box>
        <Box>
          <Text fontWeight="bold">Изменение пароля</Text>
          <FormControl>
            <FormLabel>Новый пароль</FormLabel>
            <Input name="new_password" type="password" value={formData.new_password} onChange={handleChange} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Подтверждение нового пароля</FormLabel>
            <Input name="confirm_password" type="password" value={formData.confirm_password} onChange={handleChange} />
          </FormControl>
        </Box>
        <Button colorScheme="brand" type="submit" isLoading={isLoading}>
          Сохранить изменения
        </Button>
      </VStack>
    </Box>
  );
};

export default Profile;