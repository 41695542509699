import React from 'react';
import { Flex, Link, Text, Icon, Button } from '@chakra-ui/react';
import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const Breadcrumbs = ({ items }) => {
  const navigate = useNavigate();

  return (
    <Flex align="center" mb={4}>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {index > 0 && <ChevronRightIcon mx={2} />}
          {index === items.length - 1 ? (
            <Text fontWeight="bold">{item.label}</Text>
          ) : (
            <Link as={RouterLink} to={item.href} color="brand.500">
              {item.label}
            </Link>
          )}
        </React.Fragment>
      ))}
    </Flex>
  );
};

export default Breadcrumbs;