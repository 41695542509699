import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Button,
  useDisclosure,
  Badge,
  Link,
  Flex,
  IconButton,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Wrap,
  WrapItem,
  Tooltip,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Avatar,
} from '@chakra-ui/react';

import { AddIcon, ChevronUpIcon, ChevronDownIcon, EditIcon, DeleteIcon, ExternalLinkIcon, CheckCircleIcon, WarningIcon, RepeatClockIcon } from '@chakra-ui/icons';
import { BarChartIcon } from './../../icons/BarChartIcon';

import { FaCode, FaBook, FaClipboardCheck } from 'react-icons/fa';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../utils/api';
import { toast } from '../../utils/toast';
import EditCourse from './EditCourse';
import AddLesson from '../Lessons/AddLesson';
import EditLesson from '../Lessons/EditLesson';
import DeleteLesson from '../Lessons/DeleteLesson';
import Loader from '../Loader';
import Breadcrumbs from '../Breadcrumbs';
import ManageTasks from '../Manage/ManageTasks';

const CourseDetails = () => {
  const { courseId } = useParams();
  const { hasRole } = useAuth();
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen: isEditCourseOpen, onOpen: onEditCourseOpen, onClose: onEditCourseClose } = useDisclosure();
  const { isOpen: isAddLessonOpen, onOpen: onAddLessonOpen, onClose: onAddLessonClose } = useDisclosure();
  const { isOpen: isEditLessonOpen, onOpen: onEditLessonOpen, onClose: onEditLessonClose } = useDisclosure();
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [lessonToDelete, setLessonToDelete] = useState(null);
  const { isOpen: isDeleteLessonOpen, onOpen: onDeleteLessonOpen, onClose: onDeleteLessonClose } = useDisclosure();
  const [lessonTasks, setLessonTasks] = useState({});
  const [selectedLessonForTasks, setSelectedLessonForTasks] = useState(null);
  const { isOpen: isManageTasksOpen, onOpen: onManageTasksOpen, onClose: onManageTasksClose } = useDisclosure();
  const [isLoadingLessons, setIsLoadingLessons] = useState(true);


  useEffect(() => {
    fetchCourseDetails();
    fetchLessons();
  }, [courseId]);

  const fetchCourseDetails = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/courses/${courseId}`, { withCredentials: true });
      setCourse(response.data);
    } catch (error) {
      console.error('Error fetching course details:', error);
      toast({
        title: "Ошибка",
        description: "Не удалось загрузить информацию о курсе",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLessons = async () => {
    setIsLoadingLessons(true);
    try {
      const response = await api.get(`/courses/${courseId}/lessons`, { withCredentials: true });
      const sortedLessons = response.data.sort((a, b) => (a.order || a.id) - (b.order || b.id));

      const lessonsWithTasks = await Promise.all(sortedLessons.map(async (lesson) => {
        const tasksResponse = await api.get(`/courses/${courseId}/lessons/${lesson.id}/tasks`, { withCredentials: true });
        setLessonTasks(prev => ({ ...prev, [lesson.id]: tasksResponse.data }));
        return { ...lesson, tasks: tasksResponse.data };
      }));

      setLessons(lessonsWithTasks);
    } catch (error) {
      console.error('Error fetching lessons:', error);
      toast({
        title: "Ошибка",
        description: "Не удалось загрузить список уроков",
        status: "error",
      });
    } finally {
      setIsLoadingLessons(false);
    }
  };

  const updateLessonTasks = async (lessonId) => {
    try {
      const response = await api.get(`/courses/${courseId}/lessons/${lessonId}/tasks`, { withCredentials: true });
      setLessons(prevLessons =>
        prevLessons.map(lesson =>
          lesson.id === lessonId
            ? { ...lesson, tasks: response.data }
            : lesson
        )
      );
    } catch (error) {
      console.error('Error fetching updated tasks:', error);
      toast({
        title: "Ошибка",
        description: "Не удалось обновить список заданий",
        status: "error",
      });
    }
  };

  const handleAddLesson = async (lessonData) => {
    try {
      await api.post(`/courses/${courseId}/lessons/add`, lessonData, { withCredentials: true });
      fetchLessons();
      onAddLessonClose();
      toast({
        title: "Урок добавлен",
        description: "Новый урок успешно добавлен",
        status: "success",
      });
    } catch (error) {
      console.error('Error adding lesson:', error);
      toast({
        title: "Ошибка",
        description: "Не удалось добавить урок",
        status: "error",
      });
    }
  };

  const handleEditLesson = async (lessonData) => {
    try {
      await api.put(`/courses/${courseId}/lessons/update/${selectedLesson.id}`, lessonData, { withCredentials: true });
      fetchLessons();
      onEditLessonClose();
      toast({
        title: "Урок обновлен",
        description: "Урок успешно обновлен",
        status: "success",
      });
    } catch (error) {
      console.error('Error updating lesson:', error);
      toast({
        title: "Ошибка",
        description: "Не удалось обновить урок",
        status: "error",
      });
    }
  };

  const handleDeleteLesson = async () => {
    if (!lessonToDelete) return;

    try {
      await api.delete(`/courses/${courseId}/lessons/delete/${lessonToDelete.id}`, { withCredentials: true });
      fetchLessons();
      toast({
        title: "Урок удален",
        description: "Урок успешно удален",
        status: "success",
      });
    } catch (error) {
      console.error('Error deleting lesson:', error);
      toast({
        title: "Ошибка",
        description: "Не удалось удалить урок",
        status: "error",
      });
    } finally {
      setLessonToDelete(null);
      onDeleteLessonClose();
    }
  };

  const moveLesson = async (lessonId, direction) => {
    const lessonIndex = lessons.findIndex(lesson => lesson.id === lessonId);
    if ((direction === 'up' && lessonIndex === 0) || (direction === 'down' && lessonIndex === lessons.length - 1)) {
      return;
    }

    const newLessons = [...lessons];
    const [movedLesson] = newLessons.splice(lessonIndex, 1);
    newLessons.splice(direction === 'up' ? lessonIndex - 1 : lessonIndex + 1, 0, movedLesson);

    const updatedLessons = newLessons.map((lesson, index) => ({
      ...lesson,
      order: index + 1
    }));

    setLessons(updatedLessons);

    try {
      await api.put(`/courses/${courseId}/lessons/reorder`, updatedLessons, { withCredentials: true });
      toast({
        title: "Порядок уроков обновлен",
        status: "success",
      });
    } catch (error) {
      console.error('Error updating lesson order:', error);
      toast({
        title: "Ошибка",
        description: "Не удалось обновить порядок уроков",
        status: "error",
      });
      fetchLessons();
    }
  };

  const handleLessonClick = (lessonId) => {
    navigate(`/courses/${courseId}/lessons/${lessonId}`);
  };

  const handleTaskClick = (lessonId, taskId) => {
    navigate(`/courses/${courseId}/lessons/${lessonId}/tasks/${taskId}`);
  };

  const handleAccordionClick = (e) => {
    e.stopPropagation();
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!course) {
    return <Box>Курс не найден</Box>;
  }

  return (
    <Box maxWidth="1200px" margin="auto" mt={8} p={4}>
      <Breadcrumbs
        items={[
          { label: 'Панель управления', href: '/dashboard' },
          { label: course ? course.name : 'Загрузка...' },
        ]}
      />
      <VStack align="stretch" spacing={6}>
        <Card>
          <CardHeader>
            <Flex justify="space-between" align="center">
              <Heading size="lg">{course.name}</Heading>
              {hasRole(['teacher', 'admin']) && (
                <HStack>
                  <Button colorScheme="brand" onClick={onEditCourseOpen}>Редактировать курс</Button>
                  <IconButton
                    icon={<BarChartIcon />}
                    aria-label="Course Statistics"
                    as={RouterLink}
                    to={`/courses/${courseId}/statistics`}
                    colorScheme="brand"
                  />
                </HStack>
              )}
            </Flex>
          </CardHeader>
          <CardBody>
            <VStack align="stretch" spacing={4}>
              <Box>
                <Text fontWeight="bold" mb={2}>Преподаватели:</Text>
                <VStack align="stretch" spacing={2}>
                  {course.teachers && course.teachers.map(teacher => (
                    <HStack key={teacher.id}>
                      <Avatar
                        size="md"
                        name={`${teacher.first_name} ${teacher.last_name}`}
                        src={teacher.avatar_url}
                      />
                      <Text>{`${teacher.last_name} ${teacher.first_name}`}</Text>
                    </HStack>
                  ))}
                </VStack>
              </Box>
              <Box>
                <Text fontWeight="bold" mb={2}>Группы:</Text>
                <Wrap>
                  {course.groups && course.groups.map(group => (
                    <WrapItem key={group.id}>
                      <Badge colorScheme="blue" p={2} borderRadius="full">
                        {group.group_name}
                      </Badge>
                    </WrapItem>
                  ))}
                </Wrap>
              </Box>
            </VStack>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <Flex justify="space-between" align="center">
              <Heading size="md">Уроки</Heading>
              {hasRole(['teacher', 'admin']) && (
                <Button
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  size="sm"
                  onClick={onAddLessonOpen}
                >
                  Добавить урок
                </Button>
              )}
            </Flex>
          </CardHeader>
          <CardBody>
            {isLoadingLessons ? (
              <Loader />
            ) : lessons.length === 0 ? (
              <Text>В данный момент уроков нет. Они будут добавлены позже.</Text>
            ) : (
              <VStack spacing={4} align="stretch">
                {lessons.map((lesson, index) => (
                  <Card key={lesson.id} variant="outline" onClick={() => handleLessonClick(lesson.id)} cursor="pointer" _hover={{ boxShadow: 'md' }}>
                    <CardHeader>
                      <Flex justify="space-between" align="center">
                        <Heading size="sm">{lesson.title}</Heading>
                        {hasRole(['teacher', 'admin']) && (
                          <HStack onClick={(e) => e.stopPropagation()}>
                            <IconButton
                              icon={<ChevronUpIcon />}
                              size="sm"
                              onClick={(e) => { e.stopPropagation(); moveLesson(lesson.id, 'up'); }}
                              isDisabled={index === 0}
                              aria-label="Move lesson up"
                            />
                            <IconButton
                              icon={<ChevronDownIcon />}
                              size="sm"
                              onClick={(e) => { e.stopPropagation(); moveLesson(lesson.id, 'down'); }}
                              isDisabled={index === lessons.length - 1}
                              aria-label="Move lesson down"
                            />
                            <IconButton
                              icon={<EditIcon />}
                              size="sm"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedLesson(lesson);
                                onEditLessonOpen();
                              }}
                              aria-label="Edit lesson"
                            />
                            <IconButton
                              icon={<DeleteIcon />}
                              size="sm"
                              colorScheme="red"
                              onClick={(e) => {
                                e.stopPropagation();
                                setLessonToDelete(lesson);
                                onDeleteLessonOpen();
                              }}
                              aria-label="Delete lesson"
                            />
                          </HStack>
                        )}
                      </Flex>
                    </CardHeader>
                    <CardBody>
                      <Text mb={4}>{lesson.description}</Text>
                      {lesson.tasks && lesson.tasks.length > 0 ? (
                        <Box onClick={handleAccordionClick}>
                          <Accordion allowToggle>
                            <AccordionItem>
                              <h2>
                                <AccordionButton>
                                  <Box flex="1" textAlign="left">
                                    <Flex align="center">
                                      <FaClipboardCheck color="green" />
                                      <Text fontWeight="bold" ml={2}>
                                        Задания ({lesson.tasks.length})
                                      </Text>
                                    </Flex>
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                              </h2>
                              <AccordionPanel pb={4}>
                                <VStack align="stretch" spacing={2}>
                                  {lesson.tasks.map(task => (
                                    <HStack
                                      key={task.id}
                                      spacing={4}
                                      p={2}
                                      borderWidth={1}
                                      borderRadius="md"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleTaskClick(lesson.id, task.id);
                                      }}
                                      cursor="pointer"
                                      _hover={{ bg: "gray.100" }}
                                    >
                                      {task.type === 'code' && <FaCode color="blue" />}
                                      {task.type === 'theory' && <FaBook color="purple" />}
                                      <Text flex={1}>{task.title}</Text>
                                      <Tooltip label={task.status === 'completed' ? 'Завершено' : 'Не завершено'}>
                                        <Box>
                                          {task.status === 'completed'
                                            ? <CheckCircleIcon color="green.500" />
                                            : <WarningIcon color="orange.500" />
                                          }
                                        </Box>
                                      </Tooltip>
                                    </HStack>
                                  ))}
                                </VStack>
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                        </Box>
                      ) : (
                        <Text color="gray.500" fontStyle="italic">Этот урок не содержит заданий</Text>
                      )}
                      {hasRole(['teacher', 'admin']) && (
                        <Button
                          mt={4}
                          size="sm"
                          leftIcon={<EditIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedLessonForTasks(lesson);
                            onManageTasksOpen();
                          }}
                        >
                          Управление заданиями
                        </Button>
                      )}
                    </CardBody>
                    <CardFooter>
                      <HStack spacing={4} onClick={(e) => e.stopPropagation()}>
                        {(lesson.link_notion && hasRole(['teacher', 'admin'])) && (
                          <Tooltip label="Открыть в Notion" aria-label="Открыть в Notion">
                            <Link href={lesson.link_notion} isExternal color="blue.500" display="inline-flex" alignItems="center">
                              Notion <ExternalLinkIcon mx="2px" />
                            </Link>
                          </Tooltip>
                        )}
                        {lesson.link_git && (
                          <Tooltip label="Открыть в GitHub" aria-label="Открыть в GitHub">
                            <Link href={lesson.link_git} isExternal color="blue.500" display="inline-flex" alignItems="center">
                              GitHub <ExternalLinkIcon mx="2px" />
                            </Link>
                          </Tooltip>
                        )}
                      </HStack>
                    </CardFooter>
                  </Card>
                ))}
              </VStack>
            )}
          </CardBody>
        </Card>
      </VStack>

      <EditCourse
        isOpen={isEditCourseOpen}
        onClose={onEditCourseClose}
        course={course}
        onCourseEdited={fetchCourseDetails}
      />

      <AddLesson
        isOpen={isAddLessonOpen}
        onClose={onAddLessonClose}
        onAddLesson={handleAddLesson}
      />

      <DeleteLesson
        isOpen={isDeleteLessonOpen}
        onClose={onDeleteLessonClose}
        onConfirm={handleDeleteLesson}
        lessonTitle={lessonToDelete?.title}
      />

      <EditLesson
        isOpen={isEditLessonOpen}
        onClose={onEditLessonClose}
        lesson={selectedLesson}
        onEditLesson={handleEditLesson}
      />

      <ManageTasks
        isOpen={isManageTasksOpen}
        onClose={() => {
          if (selectedLessonForTasks) {
            updateLessonTasks(selectedLessonForTasks.id);
          }
          onManageTasksClose();
        }}
        courseId={courseId}
        lessonId={selectedLessonForTasks?.id}
        onTasksChange={(updatedTasks) => {
          if (selectedLessonForTasks) {
            setLessons(prevLessons =>
              prevLessons.map(lesson =>
                lesson.id === selectedLessonForTasks.id
                  ? { ...lesson, tasks: updatedTasks }
                  : lesson
              )
            );
          }
        }}
      />

    </Box>
  );
};

export default CourseDetails;