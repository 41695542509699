import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import api from '../../utils/api';
import { toast } from '../../utils/toast';
import ManageGroups from './../Manage/ManageGroups';
import ManageTeachers from './../Manage/ManageTeachers';

const EditCourse = ({ isOpen, onClose, course, onCourseEdited }) => {
  const [courseData, setCourseData] = useState({ name: '', link_notion: '', link_git: '' });
  const [groups, setGroups] = useState([]);
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    if (course) {
      setCourseData({
        name: course.name || '',
        link_notion: course.link_notion || '',
        link_git: course.link_git || '',
      });
      setGroups(course.groups || []);
      setTeachers(course.teachers || []);
    }
  }, [course]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!course) {
      toast({
        title: "Ошибка",
        description: "Данные курса не загружены",
        status: "error",
      });
      return;
    }
    try {
      const response = await api.put(`/courses/update/${course.id}`, courseData, { withCredentials: true });
      toast({
        title: "Курс обновлен",
        description: "Курс успешно обновлен",
        status: "success",
      });
      onCourseEdited(response.data);
      onClose();
    } catch (error) {
      toast({
        title: "Ошибка",
        description: "Не удалось обновить курс",
        status: "error",
      });
    }
  };

  const handleGroupsChange = async (newGroups) => {
    if (!course) return;
    try {
      await api.post(`/courses/${course.id}/groups/add`, newGroups.map(g => g.id), { withCredentials: true });
      setGroups(newGroups);
      toast({
        title: "Группы обновлены",
        description: "Группы курса успешно обновлены",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Ошибка",
        description: "Не удалось обновить группы курса",
        status: "error",
      });
    }
  };

  const handleTeachersChange = async (newTeachers) => {
    if (!course) return;
    try {
      await api.post(`/courses/${course.id}/teachers/add`, newTeachers.map(t => t.id), { withCredentials: true });
      setTeachers(newTeachers);
      toast({
        title: "Преподаватели обновлены",
        description: "Преподаватели курса успешно обновлены",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Ошибка",
        description: "Не удалось обновить преподавателей курса",
        status: "error",
      });
    }
  };

  if (!course) {
    return null; // или можно вернуть заглушку, например, <div>Loading...</div>
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Редактировать курс</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs>
            <TabList>
              <Tab>Основная информация</Tab>
              <Tab>Группы</Tab>
              <Tab>Преподаватели</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <VStack spacing={4} as="form" onSubmit={handleSubmit}>
                  <FormControl>
                    <FormLabel>Название курса</FormLabel>
                    <Input 
                      value={courseData.name} 
                      onChange={(e) => setCourseData({...courseData, name: e.target.value})}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Ссылка Notion</FormLabel>
                    <Input 
                      value={courseData.link_notion} 
                      onChange={(e) => setCourseData({...courseData, link_notion: e.target.value})}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Ссылка Git</FormLabel>
                    <Input 
                      value={courseData.link_git} 
                      onChange={(e) => setCourseData({...courseData, link_git: e.target.value})}
                    />
                  </FormControl>
                </VStack>
              </TabPanel>
              <TabPanel>
                <ManageGroups 
                  courseId={course.id} 
                  currentGroups={groups} 
                  onGroupsChange={handleGroupsChange} 
                />
              </TabPanel>
              <TabPanel>
                <ManageTeachers 
                  courseId={course.id} 
                  currentTeachers={teachers} 
                  onTeachersChange={handleTeachersChange} 
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brand" mr={3} onClick={handleSubmit}>
            Сохранить
          </Button>
          <Button variant="ghost" onClick={onClose}>Отмена</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditCourse;