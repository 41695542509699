import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import api from '../../utils/api';
import { toast } from '../../utils/toast';

const AddGroup = ({ isOpen, onClose, onGroupAdded }) => {
  const [groupName, setGroupName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/students/groups/add', { group_name: groupName }, { withCredentials: true });
      toast({
        title: "Группа добавлена",
        description: "Новая группа успешно добавлена",
        status: "success",
      });
      onGroupAdded(response.data);
      setGroupName('');
      onClose();
    } catch (error) {
      toast({
        title: "Ошибка",
        description: "Не удалось добавить группу",
        status: "error",
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Добавить новую группу</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} as="form" onSubmit={handleSubmit}>
            <FormControl isRequired>
              <FormLabel>Название группы</FormLabel>
              <Input
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brand" mr={3} onClick={handleSubmit}>
            Добавить
          </Button>
          <Button variant="ghost" onClick={onClose}>Отмена</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddGroup;