import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  VStack,
  Link,
  useToast,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import api from '../../utils/api';
import SmallLoader from './../SmallLoader';
import { SolutionEditor, SolutionHistoryAccordion, SolutionStatus } from './SolutionViewer';

const StudentSolution = ({ courseId, lessonId, taskId }) => {
  const [solution, setSolution] = useState(null);
  const [solutionHistory, setSolutionHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const toast = useToast();

  useEffect(() => {
    fetchSolution();
  }, [courseId, lessonId, taskId]);

  const fetchSolution = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/courses/${courseId}/lessons/${lessonId}/tasks/${taskId}/solution`);
      setSolution(response.data);
    } catch (error) {
      if (error.response && error.response.status !== 404) {
        toast({
          title: 'Ошибка',
          description: 'Не удалось загрузить решение.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSolutionHistory = async () => {
    try {
      const response = await api.get(`/courses/${courseId}/lessons/${lessonId}/tasks/${taskId}/solution/history`);
      setSolutionHistory(response.data);
      setIsHistoryModalOpen(true);
    } catch (error) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось загрузить историю решений.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return (
      <Box p={4} borderWidth="1px" borderRadius="lg">
        <VStack align="center" spacing={4}>
          <Text>Загрузка решения...</Text>
          <SmallLoader />
        </VStack>
      </Box>
    );
  }

  if (!solution) return null;

  return (
    <Box p={4} borderWidth="1px" borderRadius="lg">
      <VStack align="start" spacing={4}>
        <Text fontWeight="bold">Ваше текущее решение:</Text>
        <SolutionEditor solution={solution.solution_text} />
        {solution.file_path && (
          <Link href={solution.file_path} isExternal>
            Скачать прикрепленный файл
          </Link>
        )}
        <SolutionStatus status={solution.status} updatedAt={solution.updated_at} />
        {solution.error_message && (
          <Box width="100%">
            <Text fontWeight="bold" color="red.500">Сообщение об ошибке:</Text>
            <Box 
              bg="red.50" 
              p={2} 
              borderRadius="md" 
              borderColor="red.200"
              borderWidth="1px"
              maxHeight="150px" 
              overflowY="auto"
            >
              <pre>{solution.error_message}</pre>
            </Box>
          </Box>
        )}
        <Button onClick={fetchSolutionHistory}>Показать историю решений</Button>
      </VStack>
  
      <Modal isOpen={isHistoryModalOpen} onClose={() => setIsHistoryModalOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>История решений</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SolutionHistoryAccordion solutionHistory={solutionHistory} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => setIsHistoryModalOpen(false)}>
              Закрыть
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
  
};

export default StudentSolution;