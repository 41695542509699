import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, FormControl, FormLabel, Input, VStack, Heading, Checkbox, Text, Link } from '@chakra-ui/react';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from '../../utils/toast';

const Login = () => {
  const [credentials, setCredentials] = useState({ login: '', password: '' });
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await login(credentials);
      navigate('/dashboard');
    } catch (error) {
      let errorMessage = 'Произошла ошибка при входе';
      if (error.response?.status === 403 && error.response?.data?.detail) {
        errorMessage = error.response.data.detail;
      } else if (error.response?.data?.detail) {
        errorMessage = error.response.data.detail;
      }
      toast({
        title: 'Ошибка входа',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box maxWidth="400px" margin="auto" mt={8}>
      <VStack spacing={4} as="form" onSubmit={handleSubmit}>
        <Heading>Войти в систему</Heading>
        <FormControl>
          <FormLabel>Логин</FormLabel>
          <Input
            type="text"
            value={credentials.login}
            onChange={(e) => setCredentials({ ...credentials, login: e.target.value })}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Пароль</FormLabel>
          <Input
            type="password"
            value={credentials.password}
            onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
          />
        </FormControl>
        <Button colorScheme="brand" type="submit" isLoading={isLoading} width="100%">
          Войти
        </Button>
        <Text>
          <Link color="brand.500">Не могу войти / Не помню пароль</Link>
        </Text>
        <Text mt={4}>
          Нет аккаунта? <Link color="brand.500" href="/register">Зарегистрироваться</Link>
        </Text>
      </VStack>
    </Box>
  );
};

export default Login;
