import React, { useState, useEffect } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Switch,
    Checkbox,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    VStack,
} from '@chakra-ui/react';
import api from '../../utils/api';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [editingUser, setEditingUser] = useState(null);
    const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
    const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
    const [deletingUserId, setDeletingUserId] = useState(null);
    const toast = useToast();

    useEffect(() => {
        fetchUsers();
        fetchRoles();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await api.get('/admin/users', { withCredentials: true });
            setUsers(response.data);
        } catch (error) {
            toast({
                title: "Ошибка",
                description: "Не удалось загрузить список пользователей",
                status: "error",
            });
        }
    };

    const fetchRoles = async () => {
        try {
            const response = await api.get('/admin/roles', { withCredentials: true });
            setRoles(response.data);
        } catch (error) {
            toast({
                title: "Ошибка",
                description: "Не удалось загрузить список ролей",
                status: "error",
            });
        }
    };

    const handleActivate = async (userId, isActive) => {
        try {
            await api.put(`/admin/users/${userId}/activate`, { is_active: isActive }, { withCredentials: true });
            fetchUsers();
            toast({
                title: "Успех",
                description: `Пользователь ${isActive ? 'активирован' : 'деактивирован'}`,
                status: "success",
            });
        } catch (error) {
            toast({
                title: "Ошибка",
                description: "Не удалось изменить статус пользователя",
                status: "error",
            });
        }
    };

    const handleRoleChange = async (userId, roleIds) => {
        try {
            await api.put(`/admin/users/${userId}/roles`, { role_ids: roleIds }, { withCredentials: true });
            fetchUsers();
            toast({
                title: "Успех",
                description: "Роли пользователя изменены",
                status: "success",
            });
        } catch (error) {
            toast({
                title: "Ошибка",
                description: "Не удалось изменить роли пользователя",
                status: "error",
            });
        }
    };

    const handleDeleteUser = async () => {
        try {
            await api.delete(`/admin/users/${deletingUserId}`, { withCredentials: true });
            fetchUsers();
            setIsDeleteUserModalOpen(false);
            toast({
                title: "Успех",
                description: "Пользователь удален",
                status: "success",
            });
        } catch (error) {
            toast({
                title: "Ошибка",
                description: "Не удалось удалить пользователя",
                status: "error",
            });
        }
    };

    const handleUpdateUser = async () => {
        try {
            await api.put(`/admin/users/${editingUser.id}`, editingUser, { withCredentials: true });
            fetchUsers();
            setIsEditUserModalOpen(false);
            toast({
                title: "Успех",
                description: "Данные пользователя обновлены",
                status: "success",
            });
        } catch (error) {
            toast({
                title: "Ошибка",
                description: "Не удалось обновить данные пользователя",
                status: "error",
            });
        }
    };

    return (
        <>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Логин</Th>
                        <Th>Email</Th>
                        <Th>Имя</Th>
                        <Th>Фамилия</Th>
                        <Th>Активен</Th>
                        <Th>Роли</Th>
                        <Th>Действия</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {users.map((user) => (
                        <Tr key={user.id}>
                            <Td>{user.id}</Td>
                            <Td>{user.login}</Td>
                            <Td>{user.email}</Td>
                            <Td>{user.first_name}</Td>
                            <Td>{user.last_name}</Td>
                            <Td>
                                <Switch
                                    isChecked={user.s_active}
                                    onChange={() => handleActivate(user.id, !user.s_active)}
                                />
                            </Td>
                            <Td>
                                {roles.map((role) => (
                                    <Checkbox
                                        key={role.id}
                                        isChecked={user.roles.some(r => r.id === role.id)}
                                        onChange={(e) => {
                                            const newRoleIds = e.target.checked
                                                ? [...user.roles.map(r => r.id), role.id]
                                                : user.roles.filter(r => r.id !== role.id).map(r => r.id);
                                            handleRoleChange(user.id, newRoleIds);
                                        }}
                                    >
                                        {role.role_name}
                                    </Checkbox>
                                ))}
                            </Td>
                            <Td>
                                <Button onClick={() => {
                                    setEditingUser(user);
                                    setIsEditUserModalOpen(true);
                                }} size="sm" mr={2}>
                                    Редактировать
                                </Button>
                                <Button onClick={() => {
                                    setDeletingUserId(user.id);
                                    setIsDeleteUserModalOpen(true);
                                }} colorScheme="red" size="sm">
                                    Удалить
                                </Button>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            {/* Модальное окно для редактирования пользователя */}
            <Modal isOpen={isEditUserModalOpen} onClose={() => setIsEditUserModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Редактировать пользователя</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4}>
                            <FormControl>
                                <FormLabel>Логин</FormLabel>
                                <Input
                                    value={editingUser?.login || ''}
                                    onChange={(e) => setEditingUser({ ...editingUser, login: e.target.value })}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    value={editingUser?.email || ''}
                                    onChange={(e) => setEditingUser({ ...editingUser, email: e.target.value })}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Имя</FormLabel>
                                <Input
                                    value={editingUser?.first_name || ''}
                                    onChange={(e) => setEditingUser({ ...editingUser, first_name: e.target.value })}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Фамилия</FormLabel>
                                <Input
                                    value={editingUser?.last_name || ''}
                                    onChange={(e) => setEditingUser({ ...editingUser, last_name: e.target.value })}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Новый пароль</FormLabel>
                                <Input
                                    type="password"
                                    onChange={(e) => setEditingUser({ ...editingUser, password: e.target.value })}
                                />
                            </FormControl>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleUpdateUser}>
                            Обновить
                        </Button>
                        <Button variant="ghost" onClick={() => setIsEditUserModalOpen(false)}>Отмена</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Модальное окно для подтверждения удаления пользователя */}
            <Modal isOpen={isDeleteUserModalOpen} onClose={() => setIsDeleteUserModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Подтверждение удаления</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Вы уверены, что хотите удалить этого пользователя?
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} onClick={handleDeleteUser}>
                            Удалить
                        </Button>
                        <Button variant="ghost" onClick={() => setIsDeleteUserModalOpen(false)}>Отмена</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default UserManagement;