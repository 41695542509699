import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

const Loader = () => {
  return (
    <Flex 
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex="9999"
      backgroundColor="rgba(255, 255, 255, 0.8)"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="brand.500"
        size="xl"
      />
    </Flex>
  );
};

export default Loader;