import React, { useState, useRef, useCallback } from 'react';
import {
  Box,
  Button,
  VStack,
  Text,
  List,
  ListItem,
  Checkbox,
  useToast,
  Progress,
} from '@chakra-ui/react';
import { AttachmentIcon } from '@chakra-ui/icons';
import api from '../../utils/api';

const GroupManagement = () => {
  const [file, setFile] = useState(null);
  const [existingGroups, setExistingGroups] = useState([]);
  const [newGroups, setNewGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const toast = useToast();
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    if (!file) {
      toast({
        title: "Ошибка",
        description: "Пожалуйста, выберите файл",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post('/admin/groups/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });
      
      setExistingGroups(response.data.existing_groups || []);
      setNewGroups(response.data.new_groups || []);
      setSelectedGroups(response.data.new_groups || []);
      
      toast({
        title: "Успешно",
        description: `Файл проанализирован. Найдено ${response.data.new_groups.length} новых групп.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      toast({
        title: "Ошибка",
        description: "Произошла ошибка при загрузке файла",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const handleGroupToggle = useCallback((groupName) => {
    setSelectedGroups(prev => 
      prev.includes(groupName)
        ? prev.filter(name => name !== groupName)
        : [...prev, groupName]
    );
  }, []);

  const handleCreateGroups = async () => {
    setIsCreating(true);
    try {
      const response = await api.post('/admin/groups/create', { group_names: selectedGroups });
      const createdGroups = response.data.created_groups || [];
        toast({
        title: "Успешно",
        description: `Создано ${createdGroups.length} новых групп.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setNewGroups(newGroups.filter(group => !createdGroups.includes(group)));
      setSelectedGroups([]);
    } catch (error) {
      console.error('Error creating groups:', error.response?.data || error);
      toast({
        title: "Ошибка",
        description: `Произошла ошибка при создании групп: ${error.response?.data?.detail || error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsCreating(false);
    }
  };

  const renderGroups = (groups, isSelectable = false) => {
    return (
      <List>
        {groups.map((group, index) => (
          <ListItem key={index}>
            {isSelectable ? (
              <Checkbox 
                isChecked={selectedGroups.includes(group)}
                onChange={() => handleGroupToggle(group)}
              >
                {group}
              </Checkbox>
            ) : (
              <Text>{group}</Text>
            )}
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Box>
      <VStack spacing={4} align="stretch">
        <input
          type="file"
          onChange={handleFileChange}
          accept=".xlsx,.xls"
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
        <Button 
          leftIcon={<AttachmentIcon />} 
          colorScheme="brand" 
          size="lg"
          boxShadow="md"
          _hover={{ boxShadow: "lg" }}
          onClick={() => fileInputRef.current?.click()}
        >
          Загрузить список групп
        </Button>
        {file && (
          <Button onClick={handleUpload} isLoading={isUploading} disabled={isUploading}>
            Анализировать файл
          </Button>
        )}
        {isUploading && <Progress value={uploadProgress} size="sm" colorScheme="brand" />}
        {existingGroups.length > 0 && (
          <Box>
            <Text fontWeight="bold">Существующие группы:</Text>
            {renderGroups(existingGroups)}
          </Box>
        )}
        {newGroups.length > 0 && (
          <Box>
            <Text fontWeight="bold">Новые группы:</Text>
            {renderGroups(newGroups, true)}
          </Box>
        )}
        {selectedGroups.length > 0 && (
          <Button 
            onClick={handleCreateGroups} 
            isLoading={isCreating}
            colorScheme="green"
            disabled={isCreating}
          >
            Создать выбранные группы ({selectedGroups.length})
          </Button>
        )}
      </VStack>
    </Box>
  );
};

export default GroupManagement;