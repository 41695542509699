import React from 'react';
import {
  Box,
  Text,
  VStack,
  Badge,
  Link,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon, TimeIcon, QuestionIcon, DownloadIcon } from '@chakra-ui/icons';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";

export const getStatusColor = (status) => {
  switch (status) {
    case 'Зачтено':
      return 'green';
    case 'Не зачтено':
      return 'red';
    case 'Ожидает проверки':
      return 'yellow';
    default:
      return 'blue';
  }
};

const getStatusIcon = (status) => {
  switch (status) {
    case 'Зачтено':
      return <CheckCircleIcon color="green.500" />;
    case 'Не зачтено':
      return <WarningIcon color="red.500" />;
    case 'На проверке':
      return <TimeIcon color="yellow.500" />;
    default:
      return <QuestionIcon color="gray.500" />;
  }
};

export const FileDownloadLink = ({ filePath }) => (
    filePath ? (
      <Tooltip label="Скачать файл">
        <Link href={filePath} isExternal>
          <Icon as={DownloadIcon} w={5} h={5} color="blue.500" />
        </Link>
      </Tooltip>
    ) : null
  );
  

export const SolutionEditor = ({ solution, readOnly = true }) => (
  <AceEditor
    mode="python"
    theme="github"
    value={solution}
    name="solution-viewer"
    fontSize={16}
    editorProps={{ $blockScrolling: true }}
    setOptions={{
      readOnly: readOnly,
      highlightActiveLine: false,
      showPrintMargin: false,
    }}
    style={{ width: '100%', height: '200px', borderRadius: '4px' }}
  />
);

export const SolutionHistoryAccordion = ({ solutionHistory }) => (
  <Accordion allowMultiple>
    {solutionHistory.map((historyItem, index) => (
      <AccordionItem 
        key={index}
        borderColor={`${getStatusColor(historyItem.status)}.200`}
        borderWidth="1px"
        borderRadius="md"
        mb={2}
      >
        <h2>
          <AccordionButton 
            _expanded={{ bg: `${getStatusColor(historyItem.status)}.50` }}
          >
            <Box flex="1" textAlign="left">
              <Flex alignItems="center">
                <Tooltip label={historyItem.status}>
                  <Box mr={2}>{getStatusIcon(historyItem.status)}</Box>
                </Tooltip>
                <Text fontWeight="bold">
                  Решение от {new Date(historyItem.created_at).toLocaleString()}
                </Text>
              </Flex>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <VStack align="start" spacing={3}>
            <Badge colorScheme={getStatusColor(historyItem.status)} fontSize="0.8em" p={1}>
              {historyItem.status}
            </Badge>
            <Text fontWeight="bold">Решение:</Text>
            <Box 
              bg="gray.50" 
              p={2} 
              borderRadius="md" 
              width="100%" 
              maxHeight="200px" 
              overflowY="auto"
            >
              <pre>{historyItem.solution_text}</pre>
            </Box>
            {historyItem.file_path && (
              <Link href={historyItem.file_path} isExternal color="blue.500">
                Скачать прикрепленный файл
              </Link>
            )}
            {historyItem.error_message && (
              <Box width="100%">
                <Text fontWeight="bold" color="red.500">Сообщение об ошибке:</Text>
                <Box 
                  bg="red.50" 
                  p={2} 
                  borderRadius="md" 
                  borderColor="red.200"
                  borderWidth="1px"
                  maxHeight="150px" 
                  overflowY="auto"
                >
                  <pre>{historyItem.error_message}</pre>
                </Box>
              </Box>
            )}
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    ))}
  </Accordion>
);

export const SolutionStatus = ({ status, updatedAt }) => (
  <Flex alignItems="center" justifyContent="space-between" width="100%">
    <Badge padding='2' borderRadius='6' colorScheme={getStatusColor(status)}>
      {status}
    </Badge>
    <Text fontSize="sm">Последнее обновление: {new Date(updatedAt).toLocaleString()}</Text>
  </Flex>
);

export const SolutionOnlyStatus = ({ status }) => (
    <Flex alignItems="center" justifyContent="space-between" width="100%">
      <Badge padding='2' borderRadius='6' colorScheme={getStatusColor(status)}>
        {status}
      </Badge>
    </Flex>
  );

  export const SolutionUpdate = ({ updatedAt }) => (
    <Flex alignItems="center" justifyContent="space-between" width="100%">

      <Text fontSize="sm">{new Date(updatedAt).toLocaleString()}</Text>
    </Flex>
  );