import React, { useState, useEffect } from 'react';
import {
    VStack,
    HStack,
    Text,
    Button,
    Select,
    List,
    ListItem,
    IconButton,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import api from '../../utils/api';
import { toast } from '../../utils/toast';
import { useAuth } from '../../contexts/AuthContext';


const ManageTeachers = ({ courseId, currentTeachers, onTeachersChange }) => {
    const [allTeachers, setAllTeachers] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState('');
    const { user } = useAuth();

    useEffect(() => {
        fetchAllTeachers();
    }, []);

    const fetchAllTeachers = async () => {
        try {
            const response = await api.get('/teacher/', { withCredentials: true });
            setAllTeachers(response.data);
        } catch (error) {
            console.error('Error fetching teachers:', error);
            toast({
                title: "Ошибка",
                description: "Не удалось загрузить список преподавателей",
                status: "error",
            });
        }
    };

    const handleAddTeacher = async () => {
        if (selectedTeacher) {
            try {
                await api.post(`/courses/${courseId}/teachers/add`, [parseInt(selectedTeacher)], { withCredentials: true });
                const teacherToAdd = allTeachers.find(t => t.id === parseInt(selectedTeacher));
                const newTeachers = [...currentTeachers, teacherToAdd];
                onTeachersChange(newTeachers);
                setSelectedTeacher('');
                toast({
                    title: "Преподаватель добавлен",
                    description: "Преподаватель успешно добавлен в курс",
                    status: "success",
                });
            } catch (error) {
                console.error('Error adding teacher:', error);
                toast({
                    title: "Ошибка",
                    description: "Не удалось добавить преподавателя в курс",
                    status: "error",
                });
            }
        }
    };

    const handleRemoveTeacher = async (teacherId) => {
        try {
            await api.delete(`/courses/${courseId}/teachers/delete/${teacherId}`, { withCredentials: true });
            const newTeachers = currentTeachers.filter(t => t.id !== teacherId);
            onTeachersChange(newTeachers);
            toast({
                title: "Преподаватель удален",
                description: "Преподаватель успешно удален из курса",
                status: "success",
            });
        } catch (error) {
            console.error('Error removing teacher:', error);
            toast({
                title: "Ошибка",
                description: "Не удалось удалить преподавателя из курса",
                status: "error",
            });
        }
    };

    const availableTeachers = allTeachers.filter(t => !currentTeachers.find(ct => ct.id === t.id));

    return (
        <VStack align="stretch" spacing={4}>
            <Text fontWeight="bold">Текущие преподаватели:</Text>
            <List spacing={2}>
                {currentTeachers.map(teacher => (
                    <ListItem key={teacher.id}>
                        <HStack justify="space-between">
                            <Text
                                fontWeight={user.id === teacher.user_id ? "bold" : "normal"}
                                color={user.id === teacher.user_id ? "brand.500" : "inherit"}
                            >
                                {teacher.first_name ?? teacher.user.first_name} {teacher.last_name ?? teacher.user.last_name}
                                {user.id === teacher.user_id && " (Вы)"}
                            </Text>
                            {(user.id !== teacher.user_id) && (
                                <IconButton
                                    icon={<DeleteIcon />}
                                    size="sm"
                                    colorScheme="red"
                                    onClick={() => handleRemoveTeacher(teacher.id)}
                                    aria-label="Remove teacher"
                                />
                            )}
                        </HStack>
                    </ListItem>
                ))}
            </List>

            <Text fontWeight="bold">Добавить преподавателя:</Text>
            <HStack>
                <Select
                    value={selectedTeacher}
                    onChange={(e) => setSelectedTeacher(e.target.value)}
                    placeholder="Выберите преподавателя"
                >
                    {availableTeachers.map(teacher => (
                        <option key={teacher.id} value={teacher.id}>
                            {teacher.user.first_name} {teacher.user.last_name}
                        </option>
                    ))}
                </Select>
                <Button onClick={handleAddTeacher} colorScheme="brand">
                    Добавить
                </Button>
            </HStack>
        </VStack>
    );
};

export default ManageTeachers;