import React, { useState, useEffect } from 'react';
import {
  VStack,
  HStack,
  Text,
  Button,
  Select,
  List,
  ListItem,
  IconButton,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import api from '../../utils/api';
import { toast } from '../../utils/toast';

const ManageGroups = ({ courseId, currentGroups, onGroupsChange }) => {
  const [allGroups, setAllGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');

  useEffect(() => {
    fetchAllGroups();
  }, []);

  const fetchAllGroups = async () => {
    try {
      const response = await api.get('/students/groups', { withCredentials: true });
      setAllGroups(response.data);
    } catch (error) {
      console.error('Error fetching groups:', error);
      toast({
        title: "Ошибка",
        description: "Не удалось загрузить список групп",
        status: "error",
      });
    }
  };

  const handleAddGroup = async () => {
    if (selectedGroup) {
      try {
        await api.post(`/courses/${courseId}/groups/add`, [parseInt(selectedGroup)], { withCredentials: true });
        const groupToAdd = allGroups.find(g => g.id === parseInt(selectedGroup));
        const newGroups = [...currentGroups, groupToAdd];
        onGroupsChange(newGroups);
        setSelectedGroup('');
        toast({
          title: "Группа добавлена",
          description: "Группа успешно добавлена в курс",
          status: "success",
        });
      } catch (error) {
        console.error('Error adding group:', error);
        toast({
          title: "Ошибка",
          description: "Не удалось добавить группу в курс",
          status: "error",
        });
      }
    }
  };

  const handleRemoveGroup = async (groupId) => {
    try {
      await api.delete(`/courses/${courseId}/groups/delete/${groupId}`, { withCredentials: true });
      const newGroups = currentGroups.filter(g => g.id !== groupId);
      onGroupsChange(newGroups);
      toast({
        title: "Группа удалена",
        description: "Группа успешно удалена из курса",
        status: "success",
      });
    } catch (error) {
      console.error('Error removing group:', error);
      toast({
        title: "Ошибка",
        description: "Не удалось удалить группу из курса",
        status: "error",
      });
    }
  };

  const availableGroups = allGroups.filter(g => !currentGroups.find(cg => cg.id === g.id));

  return (
    <VStack align="stretch" spacing={4}>
      <Text fontWeight="bold">Текущие группы:</Text>
      <List spacing={2}>
        {currentGroups.map(group => (
          <ListItem key={group.id}>
            <HStack justify="space-between">
              <Text>{group.group_name}</Text>
              <IconButton
                icon={<DeleteIcon />}
                size="sm"
                colorScheme="red"
                onClick={() => handleRemoveGroup(group.id)}
                aria-label="Remove group"
              />
            </HStack>
          </ListItem>
        ))}
      </List>

      <Text fontWeight="bold">Добавить группу:</Text>
      <HStack>
        <Select
          value={selectedGroup}
          onChange={(e) => setSelectedGroup(e.target.value)}
          placeholder="Выберите группу"
        >
          {availableGroups.map(group => (
            <option key={group.id} value={group.id}>
              {group.group_name}
            </option>
          ))}
        </Select>
        <Button onClick={handleAddGroup} colorScheme="brand">
          Добавить
        </Button>
      </HStack>
    </VStack>
  );
};

export default ManageGroups;