import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '../utils/api';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        checkAuth();
    }, []);

    const checkAuth = async () => {
        try {
            const response = await api.get('/auth/me/');
            setUser(response.data);
        } catch (error) {
            if (process.env.NODE_ENV !== 'production') {
                console.error('Failed to fetch user data:', error);
            }
            setUser(null);
        } finally {
            setLoading(false);
        }
    };

    const login = async (credentials) => {
        try {
            const response = await api.post('/auth/login/', credentials);
            await checkAuth();
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 403) {
                throw error;
            }
            throw new Error('Произошла ошибка при входе');
        }
    };

    const logout = async () => {
        try {
            await api.post('/auth/logout/');
        } catch (error) {
            if (process.env.NODE_ENV !== 'production') {
                console.error('Error during logout:', error);
            }
        } finally {
            setUser(null);
        }
    };

    const register = async (userData) => {
        const response = await api.post('/auth/register/', userData);
        return response.data;
    };

    const hasRole = (requiredRoles) => {
        if (!user || !user.roles) return false;
        return user.roles.some(role => requiredRoles.includes(role.role_name));
    };

    const updateUserAvatar = (newAvatarUrl) => {
        setUser(prevUser => ({
            ...prevUser,
            avatar_url: newAvatarUrl
        }));
    };

    const value = {
        user,
        login,
        logout,
        loading,
        checkAuth,
        register,
        hasRole,
        updateUserAvatar
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};