import React, { useState, useEffect, useCallback } from 'react';
import api from '../../utils/api';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Flex,
  Icon,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  List,
  ListItem,
  ListIcon,
  IconButton,
} from '@chakra-ui/react';
import { AttachmentIcon, DeleteIcon, InfoIcon, AddIcon } from '@chakra-ui/icons';
import { FaFileExcel } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';

const StudentFileManager = () => {
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const toast = useToast();

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const response = await api.get('/admin/student-files');
      setFiles(response.data);
    } catch (error) {
      toast({
        title: "Ошибка при загрузке файлов",
        description: error.response?.data?.detail || "Произошла ошибка",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const addNewFiles = (newFiles) => {
    const newSelectedFiles = newFiles.map(file => ({
      file,
      groupName: file.name.split('.').slice(0, -1).join('.')
    }));
    setSelectedFiles(prev => [...prev, ...newSelectedFiles]);
  };

  const handleGroupNameChange = (index, e) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles[index].groupName = e.target.value;
    setSelectedFiles(newSelectedFiles);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    const filesToUpload = selectedFiles.filter(item => item.file && item.groupName);
    if (filesToUpload.length === 0) {
      toast({
        title: "Ошибка",
        description: "Пожалуйста, выберите файл и введите название группы",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    for (let item of filesToUpload) {
      const formData = new FormData();
      formData.append('file', item.file);
      formData.append('group_name', item.groupName);

      try {
        await api.post('/admin/upload-student-file', formData);
        toast({
          title: "Файл загружен",
          description: `Файл ${item.file.name} успешно загружен`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: "Ошибка при загрузке файла",
          description: `Ошибка при загрузке файла ${item.file.name}: ${error.response?.data?.detail || "Произошла ошибка"}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }

    fetchFiles();
    setSelectedFiles([]);
  };

  const handleDeleteFile = async (fileId) => {
    try {
      await api.delete(`/admin/student-files/${fileId}`);
      toast({
        title: "Файл удален",
        description: "Файл студентов успешно удален",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchFiles();
    } catch (error) {
      toast({
        title: "Ошибка при удалении файла",
        description: error.response?.data?.detail || "Произошла ошибка",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const addFileInput = () => {
    setSelectedFiles([...selectedFiles, { file: null, groupName: '' }]);
  };

  const removeFileInput = (index) => {
    const newSelectedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(newSelectedFiles);
  };

  const onDrop = useCallback(acceptedFiles => {
    addNewFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls']
    }
  });

  return (
    <Box>
      <Heading size="lg" mb={6}>Управление файлами студентов</Heading>
      
      <Alert status="info" mb={6}>
        <Box flex="1">
          <AlertTitle>Структура Excel файла</AlertTitle>
          <AlertDescription>
            Пожалуйста, убедитесь, что ваш Excel файл имеет следующую структуру:
            <List spacing={2} mt={2}>
              <ListItem>
                <ListIcon as={InfoIcon} color="blue.500" />
                Первый столбец: ID студента (номер без буквы 's')
              </ListItem>
              <ListItem>
                <ListIcon as={InfoIcon} color="blue.500" />
                Второй столбец: ФИО студента
              </ListItem>
            </List>
          </AlertDescription>
        </Box>
      </Alert>

      <VStack as="form" onSubmit={handleFileUpload} spacing={4} align="stretch" mb={8} p={6} borderWidth={1} borderRadius="lg" boxShadow="md">
        <Box 
          {...getRootProps()} 
          p={10} 
          border="2px dashed" 
          borderColor={isDragActive ? "blue.500" : "gray.200"}
          borderRadius="md"
          textAlign="center"
        >
          <input {...getInputProps()} />
          {
            isDragActive ?
              <Text>Перетащите файлы сюда...</Text> :
              <Text>Перетащите файлы сюда или кликните для выбора</Text>
          }
        </Box>
        {selectedFiles.map((item, index) => (
          <Flex key={index} direction={{ base: "column", md: "row" }} align="center" justify="space-between">
            <FormControl flex={1} mr={{ base: 0, md: 4 }} mb={{ base: 4, md: 0 }}>
              <Flex align="center" justify="center" p={2} borderWidth={1} borderRadius="md" borderStyle="dashed">
                <Icon as={FaFileExcel} w={6} h={6} mr={2} color="green.500" />
                <Text>{item.file ? item.file.name : "Файл не выбран"}</Text>
              </Flex>
            </FormControl>
            <FormControl flex={1}>
              <Input
                placeholder="Название группы"
                value={item.groupName}
                onChange={(e) => handleGroupNameChange(index, e)}
              />
            </FormControl>
            <IconButton
              icon={<DeleteIcon />}
              onClick={() => removeFileInput(index)}
              colorScheme="red"
              ml={2}
            />
          </Flex>
        ))}

        <Button
          type="submit"
          colorScheme="blue"
          leftIcon={<AttachmentIcon />}
          isDisabled={selectedFiles.length === 0 || selectedFiles.every(item => !item.file || !item.groupName)}
        >
          Загрузить файлы
        </Button>
      </VStack>

      <Heading size="md" mb={4}>Загруженные файлы</Heading>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Имя файла</Th>
            <Th>Группа</Th>
            <Th>Действия</Th>
          </Tr>
        </Thead>
        <Tbody>
          {files.map(file => (
            <Tr key={file.id}>
              <Td>
                <Flex align="center">
                  <Icon as={FaFileExcel} w={5} h={5} mr={2} color="green.500" />
                  {file.filename}
                </Flex>
              </Td>
              <Td>{file.group_name}</Td>
              <Td>
                <Button
                  onClick={() => handleDeleteFile(file.id)}
                  colorScheme="red"
                  size="sm"
                  leftIcon={<DeleteIcon />}
                >
                  Удалить
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default StudentFileManager;