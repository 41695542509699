import React from 'react';
import DOMPurify from 'dompurify';
import { Box, Text, Link, Image, Table, Thead, Tbody, Tr, Th, Td, useClipboard, Button, HStack, Badge } from '@chakra-ui/react';
import { ExternalLinkIcon, CheckIcon, CopyIcon } from '@chakra-ui/icons';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx';
import javascript from 'react-syntax-highlighter/dist/esm/languages/prism/javascript';
import python from 'react-syntax-highlighter/dist/esm/languages/prism/python';
import java from 'react-syntax-highlighter/dist/esm/languages/prism/java';
import csharp from 'react-syntax-highlighter/dist/esm/languages/prism/csharp';
import cpp from 'react-syntax-highlighter/dist/esm/languages/prism/cpp';
import { InlineMath, BlockMath } from 'react-katex';

SyntaxHighlighter.registerLanguage('jsx', jsx);
SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('python', python);
SyntaxHighlighter.registerLanguage('java', java);
SyntaxHighlighter.registerLanguage('csharp', csharp);
SyntaxHighlighter.registerLanguage('cpp', cpp);

const CodeBlock = ({ code, language }) => {
  const { hasCopied, onCopy } = useClipboard(code);

  const getDisplayLanguage = (lang) => {
    const langMap = {
      'cpp': 'C++',
      'csharp': 'C#',
      'javascript': 'JavaScript',
      'python': 'Python',
      'java': 'Java',
      'bash': 'Bash'
    };
    const cleanLang = lang.replace('CodeLang.', '').toLowerCase();
    return langMap[cleanLang] || cleanLang;
  };

  const displayLanguage = getDisplayLanguage(language);
  const prismLanguage = language.replace('CodeLang.', '').toLowerCase();

  return (
    <Box position="relative" mb={4} borderRadius="md" overflow="hidden">
      <HStack justifyContent="space-between" bg="gray.700" p={2}>
        <Badge color={"#EDF2F7"} bg="transparent" textTransform="none">{displayLanguage}</Badge>
        <Button size="sm" onClick={onCopy} leftIcon={hasCopied ? <CheckIcon /> : <CopyIcon />}>
          {hasCopied ? "Скопировано" : "Копировать"}
        </Button>
      </HStack>
      <SyntaxHighlighter
        language={prismLanguage}
        style={okaidia}
        customStyle={{ margin: 0, borderRadius: '0 0 4px 4px' }}
      >
        {code}
      </SyntaxHighlighter>
    </Box>
  );
};

const processText = (text) => {
  return text.replace(/\\(?!\s|\n)/g, '');
};

const InlineCode = ({ children }) => (
  <Box
    as="code"
    bg="gray.100"
    color="red.600"
    px={2}
    py={1}
    borderRadius="md"
    fontSize="1.1em"
    fontFamily="monospace"
    display="inline-block"
    textAlign='left'
  >
    {children}
  </Box>
);

const ProcessedText = ({ children }) => {
  const processContent = (content) => {
    if (typeof content === 'string') {
      return content.split('\n').map((line, index, array) => (
        <React.Fragment key={index}>
          {line}
          {index < array.length - 1 && <br />}
        </React.Fragment>
      ));
    }
    if (Array.isArray(content)) {
      return content.map((child, index) => (
        <React.Fragment key={index}>
          {processContent(child)}
        </React.Fragment>
      ));
    }
    return content;
  };

  return processContent(children);
};

export const notionContentStyles = {
  'h1, h2, h3, h4, h5, h6': {
    fontWeight: 'bold',
    marginTop: '1em',
    marginBottom: '0.5em',
  },
  'p': {
    marginBottom: '1em',
    textAlign: 'justify',
    lineHeight: '1.6em',
  },
  'img': {
    marginTop: '1em',
    marginBottom: '1em',
  },
  'ul, ol': {
    marginBottom: '1em',
    paddingLeft: '2em',
  },
  'li': {
    marginBottom: '0.5em',
  },
  'blockquote': {
    borderLeft: '4px solid',
    borderColor: 'gray.200',
    paddingLeft: '1em',
    fontStyle: 'italic',
    marginBottom: '1em',
  },
  'hr': {
    marginY: '2em',
  },
  '.katex-display': {
    overflowX: 'auto',
    overflowY: 'hidden',
    fontSize: '1.3em',
  },
  '.katex-html': {
    display: 'none',
  },
};

const tableStyles = {
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '1em',
  },
  th: {
    border: '1px solid',
    borderColor: 'gray.300',
    padding: '0.5em',
    backgroundColor: 'gray.100',
    fontWeight: 'bold',
  },
  td: {
    border: '1px solid',
    borderColor: 'gray.300',
    padding: '0.5em',
    verticalAlign: 'top',
  },
};

const processTableHTML = (tableElement) => {
  const rows = Array.from(tableElement.querySelectorAll('tr'));
  return rows.map(row => {
    const cells = Array.from(row.querySelectorAll('th, td'));
    return cells.map(cell => ({
      content: cell.innerHTML.trim(),
      isHeader: cell.tagName.toLowerCase() === 'th'
    }));
  });
};

export const NotionTable = ({ tableElement }) => {
  const tableData = processTableHTML(tableElement);
  const hasHeader = tableData.length > 0 && 
                    tableData[0].some(cell => cell.isHeader) && 
                    tableData[0].some(cell => cell.content !== '');
  return (
    <Table {...tableStyles.table}>
      {hasHeader && (
        <Thead>
          <Tr>
            {tableData[0].map((cell, index) => (
              <Th key={index} {...tableStyles.th} dangerouslySetInnerHTML={{ __html: cell.content }} />
            ))}
          </Tr>
        </Thead>
      )}
      <Tbody>
      {tableData.slice(1).map((row, rowIndex) => (
          <Tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <Td key={cellIndex} {...tableStyles.td} dangerouslySetInnerHTML={{ __html: cell.content }} />
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export const processNotionContent = (content) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');

  const processNode = (node) => {
    if (node.nodeType === Node.ELEMENT_NODE) {

      if (node.tagName === 'TABLE') {
        return <NotionTable tableElement={node} />;
      }

      if (node.tagName === 'PRE' && node.querySelector('code')) {
        const codeElement = node.querySelector('code');
        const language = codeElement.className.replace('language-', '');
        const code = codeElement.textContent;
        return <CodeBlock key={Math.random()} code={code} language={language} />;
      }

      if (node.tagName === 'SCRIPT' && node.getAttribute('type') === 'math/tex; mode=display') {
        const formula = node.textContent.replace(/^\$\$|\$\$$/g, '');
        return <BlockMath key={Math.random()}>{formula}</BlockMath>;
      }
  
      if (node.tagName === 'SCRIPT' && node.getAttribute('type') === 'math/tex') {
        const formula = node.textContent.replace(/^\$|\$$/g, '');
        return <InlineMath key={Math.random()}>{formula}</InlineMath>;
      }
  

      if (node.tagName === 'CODE') {
        return <InlineCode key={Math.random()}>{node.textContent}</InlineCode>;
      }

      if (node.tagName === 'A') {
        return (
          <Link
            key={Math.random()}
            href={node.href}
            isExternal
            color="blue.500"
            textDecoration="underline"
          >
            {node.textContent}
            <ExternalLinkIcon mx="2px" />
          </Link>
        );
      }

      if (node.tagName === 'IMG') {
        return (
          <Image
            key={Math.random()}
            src={node.src}
            alt={node.alt || ''}
            width={node.width || 'auto'}
            height={node.height || 'auto'}
            maxWidth="100%"
            mb={4}
          />
        );
      }

      if (node.tagName === 'DIV' && node.classList.contains('math')) {
        const formula = node.textContent.replace(/^\$\$|\$\$$/g, '');
        return <BlockMath key={Math.random()}>{formula}</BlockMath>;
      }


      if (node.tagName === 'P') {
        return (
          <Text key={Math.random()} mb={4}>
            <ProcessedText>
              {Array.from(node.childNodes).map(processNode)}
            </ProcessedText>
          </Text>
        );
      }
      
      const children = Array.from(node.childNodes).map(processNode).filter(Boolean);
      return children.length > 0 ? React.createElement(node.tagName.toLowerCase(), { key: Math.random() }, ...children) : null;
    }
    if (node.nodeType === Node.TEXT_NODE) {
      return processText(node.textContent);
    }
    return null;
  };

  return Array.from(doc.body.childNodes).map(processNode).filter(Boolean);
};