import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Heading,
  Spinner,
  Tooltip,
  Button,
  Icon,
  Progress,
  VStack,
  HStack,
  Flex,
  Avatar,
} from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon, TimeIcon, QuestionIcon } from '@chakra-ui/icons';
import api from '../../utils/api';
import { toast } from '../../utils/toast';

const CourseStatistics = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [courseData, setCourseData] = useState(null);
  const [students, setStudents] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchCourseStatistics();
  }, [courseId]);

  const fetchCourseStatistics = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/courses/${courseId}/statistics`, { withCredentials: true });
      setCourseData(response.data.course);
      setStudents(response.data.students);
      
      // Сортировка заданий по названию
      const sortedTasks = response.data.tasks.sort((a, b) => a.title.localeCompare(b.title));
      setTasks(sortedTasks);
      
      setStatistics(response.data.statistics);
    } catch (error) {
      toast({
        title: "Ошибка",
        description: "Не удалось загрузить статистику курса",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Зачтено':
        return <Tooltip label="Зачтено"><Icon as={CheckCircleIcon} color="green.500" boxSize={6} /></Tooltip>;
      case 'Не зачтено':
        return <Tooltip label="Не зачтено"><Icon as={WarningIcon} color="red.500" boxSize={6} /></Tooltip>;
      case 'На проверке':
        return <Tooltip label="На проверке"><Icon as={TimeIcon} color="yellow.500" boxSize={6} /></Tooltip>;
      default:
        return <Tooltip label="Не начато"><Icon as={QuestionIcon} color="gray.500" boxSize={6} /></Tooltip>;
    }
  };

  const calculateStudentStats = (studentId) => {
    const studentStats = statistics[studentId] || [];
    const totalTasks = tasks.length;
    const completedTasks = studentStats.filter(stat => stat.status === 'Зачтено').length;
    const failedTasks = studentStats.filter(stat => stat.status === 'Не зачтено').length;
    const pendingTasks = studentStats.filter(stat => stat.status === 'На проверке').length;
    const notStartedTasks = totalTasks - completedTasks - failedTasks - pendingTasks;
    const completionPercentage = totalTasks > 0 ? ((completedTasks / totalTasks) * 100).toFixed(2) : 0;

    return {
      completed: completedTasks,
      failed: failedTasks,
      pending: pendingTasks,
      notStarted: notStartedTasks,
      total: totalTasks,
      percentage: completionPercentage
    };
  };

  const navigateToTask = (taskId) => {
    const task = tasks.find(t => t.id === taskId);
    if (task) {
      navigate(`/courses/${courseId}/lessons/${task.lesson_id}/tasks/${taskId}`);
    }
  };

  const StudentStats = ({ stats }) => (
    <VStack align="stretch" spacing={2}>
      <Progress value={stats.percentage} colorScheme="green" size="sm" />
      <Flex justify="space-between">
        <Text fontSize="sm">Выполнено: {stats.completed} из {stats.total} ({stats.percentage}%)</Text>
        <HStack spacing={2}>
          <Tooltip label="Зачтено">
            <Flex align="center">
              <Icon as={CheckCircleIcon} color="green.500" mr={1} />
              <Text fontSize="sm">{stats.completed}</Text>
            </Flex>
          </Tooltip>
          <Tooltip label="На проверке">
            <Flex align="center">
              <Icon as={TimeIcon} color="yellow.500" mr={1} />
              <Text fontSize="sm">{stats.pending}</Text>
            </Flex>
          </Tooltip>
          <Tooltip label="Не зачтено">
            <Flex align="center">
              <Icon as={WarningIcon} color="red.500" mr={1} />
              <Text fontSize="sm">{stats.failed}</Text>
            </Flex>
          </Tooltip>
          <Tooltip label="Не начато">
            <Flex align="center">
              <Icon as={QuestionIcon} color="gray.500" mr={1} />
              <Text fontSize="sm">{stats.notStarted}</Text>
            </Flex>
          </Tooltip>
        </HStack>
      </Flex>
    </VStack>
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box maxWidth="1200px" margin="auto" mt={8} p={4}>
      <Heading as="h1" size="xl" mb={4}>Статистика курса: {courseData?.name}</Heading>
      <Box overflowX="auto">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Студент</Th>
              {tasks.map((task) => (
                <Th key={task.id}>
                  <Button
                    variant="link"
                    onClick={() => navigateToTask(task.id)}
                    color="blue.500"
                  >
                    {task.title}
                  </Button>
                </Th>
              ))}
              <Th width="300px">Статистика</Th>
            </Tr>
          </Thead>
          <Tbody>
            {students.map(student => {
              const studentStats = calculateStudentStats(student.id);
              return (
                <Tr key={student.id}>
                  <Td>
                    <HStack>
                      <Avatar
                        size="sm"
                        name={`${student.first_name} ${student.last_name}`}
                        src={student.avatar_url}
                      />
                      <VStack align="start" spacing={0}>
                        <Text fontWeight="bold">{`${student.last_name} ${student.first_name}`}</Text>
                        <Text fontSize="sm" color="gray.500">{student.login}</Text>
                        <Text fontSize="sm" color="gray.500">Группа: {student.group || 'Не назначена'}</Text>
                      </VStack>
                    </HStack>
                  </Td>
                  {tasks.map(task => (
                    <Td key={task.id} onClick={() => navigateToTask(task.id)} cursor="pointer">
                      {getStatusIcon(statistics[student.id]?.find(stat => stat.task_id === task.id)?.status)}
                    </Td>
                  ))}
                  <Td>
                    <StudentStats stats={studentStats} />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default CourseStatistics;
